import React, { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { useCurrentUser } from '../hooks/AuthHooks';
import { listUsers } from '../graphql/queries'
import { getDashboardData } from '../graphql/custom_queries'
import { API, graphqlOperation } from 'aws-amplify';
import { UpdateUserInput } from '../API';
import LoadingIndicator from '../common/LoadingIndicator';
import DashboardCard from './DashboardCard'

const deltaSort = (a:any, b:any) => {
  return Math.round((Date.now() - Date.parse(b.inspectionDate))/(1000 * 3600 * 24)) - Math.round((Date.now() - Date.parse(a.inspectionDate))/(1000 * 3600 * 24))
}

const latestComment = (a: any) => {
  return a.items.reduce((a: any, b: any) => new Date(a.comment.createdAt).getTime() < new Date(b.comment.createdAt).getTime() ? b : a)
}

export default function Dashboard() {

  const [reviewers, setReviewers] = useState<UpdateUserInput[]>([])
  const [currentUser, setCurrentUser] = useState<string>()
  const userName = useCurrentUser()
  const getReviewers = async () => {
    const data = await API.graphql(graphqlOperation(listUsers, { filter: {status: {eq: "ACTIVE"},  or: [{ type: { eq: "REVIEWER" } }, { type: { eq: "ADMIN" } }] } })) as any
    setReviewers(data.data.listUsers.items.filter((r: any) => r._deleted !== true))
  }

  useEffect(() => {
    getReviewers()
    setCurrentUser(userName)
  }, [userName])

  const [inspections, setInspections] = useState<any[]>([])
  const getInspections = useCallback(async () => {
    const data = await API.graphql(graphqlOperation(getDashboardData, { reviewerId: currentUser })) as any
    data.data.searchInspections.items.forEach((i: any) => {
      i.latestComment = i.comments.items.length ? latestComment(i.comments) : null
    })
    setInspections(data.data.searchInspections.items.filter((i: any) => {
      return (i.reviewer ? i.reviewer.id === currentUser : false) || (i.project.reviewer ? i.project.reviewer.id === currentUser : false)}))
  }, [currentUser])

  useEffect(() => {
    getInspections()
  }, [getInspections])

  const handleChange = async (user: any) => {
    setCurrentUser(user.target.value)
  }


  if (!userName) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {'Reviewing Dashboard for '}
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={currentUser ? currentUser : userName}
            onChange={(u) => handleChange(u)}
          >
            {reviewers?.map(reviewer =>
              <MenuItem value={reviewer.id} key={reviewer.id} >
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>{`${reviewer.firstName} ${reviewer.lastName}`}</Typography>
              </MenuItem>
            )}
          </Select>
        </Typography>
      </Grid>
      { inspections.length ?
      <>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Aging Inspections - All Statuses</Typography>
        {inspections.sort((a,b) => deltaSort(a,b)).map(i =>
          <DashboardCard inspection={i} extraInfo={true} key={i.id} />
        )}
      </Grid>
      <Grid item xs={4}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>Submitted Inspections</Typography>
        {inspections.filter(j => j.status === 'SUBMITTED').map(i =>
          <DashboardCard inspection={i} extraInfo={false} key={i.id} />
        )}
      </Grid>
      <Grid item xs={4}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>Declined Inspections</Typography>
        {inspections.filter(j => j.status === 'DECLINED').map(i =>
          <DashboardCard inspection={i} extraInfo={false} key={i.id} />
        )}
      </Grid>
      <Grid item xs={4}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>Active Inspections</Typography>
        {inspections.filter(j => j.status === 'ACTIVE').map(i =>
          <DashboardCard inspection={i} extraInfo={false} key={i.id} />
        )}
      </Grid> 
      </> :
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>You have no inspections to review.</Typography>
      </Grid>
      }
    </Grid>
  );
}
