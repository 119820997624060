import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Grid,
  Switch,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Button,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  hierarchyItemsByHierarchyId,
  listHierarchies,
  listInspectionPoints
}
  from '../graphql/queries';
import { searchInspectionPoints } from '../graphql/custom_queries'
import {
  createInspectionPoint,
} from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import {
  CreateInspectionPointInput,
  InspectionPointType,
  UpdateInspectionPointInput,
} from '../API'
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import TreeItem from '@material-ui/lab/TreeItem';
import { InspectionContext } from '../inspection/InspectionContext';
import { ObservationContext } from '../inspection/ObservationContext';

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
  formControlLabel: {
    margin: 5,
  },
  fabButton: {
    maxHeight: 20,
    minHeight: 20,
    maxWidth: 20,
    minWidth: 20,
    alignSelf: "flex-end",
    marginBottom: 2,
    marginRight: 2
  },
  title: {
    textAlign: 'center',
    paddingTop: 40,
    paddingBottom: 10
  },
  reviewItem: {
    fontStyle: 'bold'
  },
});


const arrayToTree = (list: any) => {
  const map: number[] = [];
  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  let node
  const roots: any = [];

  for (const item of list) {
    node = item
    if (node.parentId !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') {
      if (list[map[node.parentId]] !== undefined) {
        list[map[node.parentId]].children?.push({ 'id': node.id, 'code': `${node.code} ${node.name}`, 'inspectionPoints': node.inspectionPoints, 'children': node.children });
      }
    } else {
      roots.push({ 'id': node.id, 'code': `${node.code} ${node.name}`, 'inspectionPoints': node.inspectionPoints, 'children': node.children });
    }
  }
  return { 'id': '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f', 'code': 'CSI Hierarchy', 'inspectionPoints': [], 'children': roots }
}

const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

type Props = {
  setNewInspectionPoint: Function,
};

export const InspectionPointSelectionTree: React.FC<Props> = ({ setNewInspectionPoint }) => {

  const { inspection } = useContext(InspectionContext);
  const { observation } = useContext(ObservationContext);
  const classes = useStyles();
  const [hierarchyItems, setHierarchyItems] = useState<any[]>([])
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])
  // const [hierarchy, setHierarchy] = useState<UpdateHierarchyInput | CreateHierarchyInput>()
  const [ipText, setIpText] = useState<string>()
  const [checked, setChecked] = useState(observation?.inspectionPointId);
  const [scoped, setScoped] = useState<boolean>(true)
  const [addInspectionPoint, setAddInspectionPoint] = useState<boolean>(false)

  const fetchHierarchyItems = useCallback(async (hierarchyId: string = '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') => {
    const inspectionPointsData = await API.graphql(graphqlOperation(listInspectionPoints, {
      filter: { or: [{ projectId: { eq: inspection?.projectId } }, { type: { eq: InspectionPointType.APPROVED } }] },
      limit: 5000
    })) as any
    const inspectionPoints = inspectionPointsData.data.listInspectionPoints.items
    const hierarchyItemsData = await API.graphql(graphqlOperation(hierarchyItemsByHierarchyId, {
      hierarchyId: hierarchyId, limit: 1200
    })) as any
    const hierarchyItems = hierarchyItemsData.data.hierarchyItemsByHierarchyId.items
      .filter((i: any) => !i._deleted)
      .sort((a: any, b: any) => a.code > b.code ? 1 : -1)
    hierarchyItems.forEach((i: any) => i.inspectionPoints = inspectionPoints.filter((p: any) => p.hierarchyItemId === i.id))
    setHierarchyItems(hierarchyItems)
    const allParentNodes: any = ['3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f']
    const map = Object.fromEntries(hierarchyItems.map((h: any) => [h.id, h]))
    let a = map[observation!.hierarchyItemId!]
    while (a) {
      allParentNodes.unshift(a.id)
      a = map[a.parentId]
    }
    setExpandedNodes(allParentNodes)
  }, [inspection, observation])

  const getParentNodes = (inspectionPoints: any[]) => {
      const allParentNodes: any = ['3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f']
      const map = Object.fromEntries(hierarchyItems.map((h: any) => [h.id, h]))
      // Open up all parent nodes.
      inspectionPoints.forEach(
        (i: any) => {
          let a = map[i.hierarchyItemId]
          while (a) {
            allParentNodes.unshift(a.id)
            a = map[a.parentId]
          }

        }
      )
      return allParentNodes
  }

  const fetchHierarchy = useCallback(async () => {
    const hierarchyData = await API.graphql(graphqlOperation(listHierarchies)) as any
    const hierarchies = hierarchyData.data.listHierarchies.items
    if (hierarchies.length) {
      // setHierarchy(hierarchies[0])
      await fetchHierarchyItems(hierarchies[0].id);
    } else {
      // setHierarchy({
      //   name: ''
      // })
    }
  }, [fetchHierarchyItems])

  useEffect(() => {
    fetchHierarchy()
  }, [fetchHierarchy])

  const handleToggle = (ip: any, node: any) => () => {
    setChecked(ip.id)
    setSelectedHierarchyItem(node)
    setNewInspectionPoint({...ip, parentNodes: getParentNodes([ip])})
  };

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };
  

  const searchChange = async (value: string) => {
    if (value !== "") {
      const inspectionPointsData = await API.graphql(graphqlOperation(searchInspectionPoints, {
        filter: { text: { regexp: `.*?${value}.*` } }
      })) as any
      const inspectionPoints = inspectionPointsData.data.searchInspectionPoints.items.filter((ip: any) => ip.type === InspectionPointType.APPROVED || ip.projectId === inspection?.projectId)
      hierarchyItems.forEach((i: any) => i.inspectionPoints = inspectionPoints.filter((p: any) => p.hierarchyItemId === i.id))
      setExpandedNodes(getParentNodes(inspectionPoints))
      setHierarchyItems(hierarchyItems)
    }
  }

  const handleAddIP = (e: any, node: string) => {
    e.preventDefault()
    setAddInspectionPoint(true)
    setSelectedHierarchyItem(node)
  }

  const newInspectionPoint = async (e: any) => {
    let ip: UpdateInspectionPointInput;
    const hierarchyItemData = await API.graphql(graphqlOperation(createInspectionPoint, {
      input: {
        type: InspectionPointType.SUBMITTED,
        hierarchyItemId: selectedHierarchyItem,
        projectId: inspection?.projectId,
        text: ipText
      } as CreateInspectionPointInput
    })) as any
    ip = hierarchyItemData.data.createInspectionPoint
    // Update hierarchy item to rerender tree.
    const hiIndex = hierarchyItems?.findIndex((i: any) => i.id === selectedHierarchyItem)
    const updatedObj = {
      ...hierarchyItems[hiIndex],
      inspectionPoints: hierarchyItems[hiIndex].inspectionPoints ? hierarchyItems[hiIndex].inspectionPoints.concat(ip) : []
    };
    const updatedHierarchyItems = [
      ...hierarchyItems.slice(0, hiIndex),
      updatedObj,
      ...hierarchyItems.slice(hiIndex + 1),
    ];
    setHierarchyItems(updatedHierarchyItems)
    setExpandedNodes(expandedNodes?.concat(selectedHierarchyItem!))
    setNewInspectionPoint(ip)
    setAddInspectionPoint(false)
  }

  const [selectedHierarchyItem, setSelectedHierarchyItem] = useState<any>()

  const renderTree = (n: any) => (
    <TreeItem
      id={n.id}
      key={n.id}
      nodeId={n.id}
      label={<Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography>{n.code}</Typography>
        {n.id !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f' &&
          <Fab onClick={(event: any) => handleAddIP(event, n.id)} className={classes.fabButton} size="small" color="primary" aria-label="add"><AddIcon /></Fab>
        }
      </Box>
      }
    >
      {n.inspectionPoints.length > 0 ?
        n.inspectionPoints.map((element: any) =>
          <TreeItem nodeId={element.id} key={element.id}
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={element.id === checked}
                    onClick={handleToggle(element, n)}
                  />
                }
                label={<Typography>{element.text}</Typography>}
                className={classes.formControlLabel}
              />
            }
          />
        )

        : null}
      {Array.isArray(n.children) ? n.children.map((blah: any) => renderTree(blah)) : null}
    </TreeItem>
  );

  return (
    <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Switch
                checked={scoped}
                onChange={() => setScoped(!scoped)}
                color="primary"
                name="scoped"
                inputProps={{ 'aria-label': 'primary checkbox' }} />
              <Typography>Scope to Inspection</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField onChange={debounce((event: any) => searchChange(event.target.value), 1000)} label="Search..." variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expandedNodes}
              onNodeToggle={handleNodeToggle}
            >
              {hierarchyItems ? renderTree(arrayToTree(scoped ? hierarchyItems.filter((n) => inspection?.scope?.includes(n.id)) : hierarchyItems)) : null}
            </TreeView>
          </Grid>
        </Grid>
      <Dialog open={addInspectionPoint}>
        <DialogTitle id="form-dialog-title">Add Inspection Point</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e: any) => setIpText(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Inspection Point Text..."
            type="text"
            fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddInspectionPoint(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => newInspectionPoint(e)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
