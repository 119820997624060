import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { ColDef, IDatasource, RowSelectedEvent } from 'ag-grid-community';
import { ClientFilter } from '../client/ClientFilter';
import { ProjectFilter } from '../project/ProjectFilter';
import { ReviewerFilter } from './ReviewerFilter';

type Props = {
  id: string
  datasource?: IDatasource
  data?: Array<any>
  children?: React.ReactNode
  onSelect?: Function
  cacheBlockSize?: number
  height?: any
  columnDefs?: ColDef[]
};

export const DataGrid: React.FC<Props> = (props) => {
  const { id, datasource, data, children, onSelect, cacheBlockSize, height = 600, columnDefs } = props
  const [gridApi, setGridApi] = useState<any>(null);

  useEffect(() => {
    function resizeColumns() {
      gridApi?.sizeColumnsToFit()
    }
 
    if (gridApi) {
      window.addEventListener('resize', resizeColumns);
      resizeColumns()
      return () => window.removeEventListener('resize', resizeColumns);
    }
  }, [gridApi])

  useEffect(() => {
    if (gridApi && datasource) {
      gridApi.setDatasource(datasource)
    }
  }, [gridApi, datasource])


  function onRowSelected(event: RowSelectedEvent) {
    onSelect && onSelect(event.node.data)
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api)

    const gridState = localStorage.getItem(`${id}-state`)
    if (gridState) {
      params.columnApi.applyColumnState({ state: JSON.parse(gridState) })
    }
    if (datasource) {
      params.api.setDatasource(datasource)
    }
  }

  const onColumnStateChanged = (params: any) => {
    let gridState = params.columnApi.getColumnState()

    if (gridState) {
      localStorage.setItem(`${id}-state`, JSON.stringify(gridState))
    }
  }

  const components = useMemo(() => {
    return {
      clientFilter: ClientFilter,
      projectFilter: ProjectFilter,
      reviewerFilter: ReviewerFilter 
    };
  }, []);

  return (
    <div className="ag-theme-material" style={{ height: height }}>
      <AgGridReact
        rowModelType={datasource ? "infinite" : undefined}
        columnDefs={columnDefs}
        cacheBlockSize={cacheBlockSize || 100}
        maxBlocksInCache={10}
        rowSelection='single'
        suppressCellFocus={true}
        onRowSelected={onRowSelected}
        components={components}
        onGridReady={onGridReady}
        rowData={data}
        onFilterChanged={onColumnStateChanged}
        onSortChanged={onColumnStateChanged}
        isExternalFilterPresent={() =>  true}
        doesExternalFilterPass={(node) => {
            return !node.data._deleted
        }}
      >
        {children}
      </AgGridReact>
    </div>
  )
}

export default DataGrid