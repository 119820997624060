import React, { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

import { useParams, useNavigate } from 'react-router-dom';
import { getUser } from '../graphql/queries';
import { updateUser } from '../graphql/mutations';
import { UpdateUserInput } from '../API';
import { ContactEditor } from '../contact/ContactEditor';
import { ContactType, UserStatus, UserType } from '../models';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';

export const UserView = () => {
  const { id } = useParams() as any;
  const navigate = useNavigate();
  const [user, setUser] = useState<UpdateUserInput>()
  const fetchUser = useCallback(async () => { 
    const userData = await API.graphql(graphqlOperation(getUser, { id: id })) as any
    const user = userData.data.getUser
    setUser(user)
  }, [id])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  const [changes, setChanges] = useState<any>(null)
  function setUserValue(key: string, value: string) {
    setChanges({
      ...changes || {},
      [key]: value
    })

    setUser({ 
      ...user, 
      [key]: value
    } as UpdateUserInput)
  }

  async function onContactChange(contactChanges: any) {
    setChanges({
      ...changes,
      ...contactChanges
    })

    setUser({
      ...user,
      ...changes
    })
  }

  const [saving, setSaving] = useState<boolean>(false)
  async function onSave() {
    try {
      setSaving(true)
      if (changes && (changes.status || changes.email || changes.phone)) {
        await API.put("cognito", "/user", { body: {
          username: user!.id,
          email: changes.email,
          phone: changes.phone,
          status: changes.status
        }})
      }

      await API.graphql(graphqlOperation(updateUser, {input: {
        id: user!.id,
        _version: user!._version,
        ...changes
      }}))

      setChanges(undefined)
    } catch (err) {
      console.log('error saving user:', err)
    } finally {
      setSaving(false)
    }
  }

  function onCancel() {
    navigate('/team')
  }

  function valid() {
    return changes !== null && changes !== undefined
      && (changes.userType === undefined || changes.userType)
      && (changes.firstName === undefined || changes.firstName)
      && (changes.lastName === undefined || changes.lastName)
      && (changes.firstName === undefined || changes.firstName)
      && (changes.companyName === undefined || changes.companyName)
      && (changes.email === undefined || changes.email)
      && (changes.phone === undefined || changes.phone.replace(/\D/g, "").length === 10)
  }

  if (!user) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Edit User
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <TextField 
            label="Username" 
            variant="outlined"
            size="small"
            fullWidth
            disabled
            value={user?.id}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="user-status-label">User Status</InputLabel>
            <Select
              labelId="user-status-label"
              value={user?.status}
              onChange={(event: any) => setUserValue('status', event.target.value)}
              label="User Status"
            >
              <MenuItem value={UserStatus.ACTIVE}>Active</MenuItem>
              <MenuItem value={UserStatus.INACTIVE}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="user-type-label">User Type</InputLabel>
            <Select
              labelId="user-type-label"
              value={user?.type}
              onChange={(event: any) => setUserValue('type', event.target.value)}
              label="User Type"
            >
              <MenuItem value={UserType.ADMIN}>Admin</MenuItem>
              <MenuItem value={UserType.EFT}>EFT</MenuItem>
              <MenuItem value={UserType.REVIEWER}>Reviewer</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ContactEditor 
            contact={{
              type: ContactType.CONTACT,
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              companyName: user.companyName,
              jobTitle: user.jobTitle,
              phone: user.phone,
              email: user.email
            }} 
            editing={true} 
            onChange={onContactChange}/>
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="outlined" 
            color="primary" 
            disabled={saving}
            onClick={onCancel}
            style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            Cancel
          </Button>
          <LoadingButton 
            variant="contained" 
            color="primary" 
            disabled={!valid()}
            onClick={onSave}
            style={{ fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserView
