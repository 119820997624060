import React, { ChangeEvent, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createPhase } from '../graphql/mutations';
import { Grid, TextField, Typography } from '@material-ui/core';

import { useParams, useNavigate } from 'react-router-dom';
import { CreatePhaseInput } from '../API';
import { PhaseStatus } from '../models';
import { LoadingButton } from '../common/LoadingButton';

export const NewPhase = () => {
  const { id } = useParams() as any

  const navigate = useNavigate();
  const [phase, setPhase] = useState<CreatePhaseInput>({
    groupId: id,
    name: '',
    status: PhaseStatus.ACTIVE,
    projectId: id
  })

  function setPhaseValue(event: ChangeEvent<HTMLInputElement>) {
    setPhase({ 
      ...phase, 
      [event.target.name]: event.target.value
    })
  }

  async function addPhase() {
    try {
      if (!phase.name) return
      const phaseData = await API.graphql(graphqlOperation(createPhase, {input: phase})) as any
      const newPhase = phaseData.data.createPhase
      navigate(`/phases/${newPhase.id}`)
    } catch (err) {
      console.log('error creating phase:', err)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Phase
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <TextField 
            label="Phase Name" 
            variant="outlined"
            size="small"
            fullWidth
            name="name"
            onChange={setPhaseValue}
            value={phase.name}
            style={{ marginBottom: 20 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton 
          variant="contained" 
          color="primary" 
          onClick={addPhase}
          disabled={!phase.name}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
