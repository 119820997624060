import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export function useCurrentUser() {
    const [username, setUsername] = useState<string|undefined>();
  
    useEffect(() => {
      let isMounted = true
      let currentUsername: string | undefined
  
      Auth.currentUserInfo()
        .then(userInfo => currentUsername = userInfo.username)
        .catch(() => {
          currentUsername = undefined
        })
        .finally(() => {
          if (isMounted) {
            setUsername(currentUsername)
          }
        })
  
      return () => { isMounted = false }
    }, [])
  
    return username;
  }