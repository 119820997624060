import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createProject } from '../graphql/mutations';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { Navigate } from 'react-router-dom';
import { AddressEditor } from '../common/AddressEditor';
import { listClients } from '../graphql/queries';
import { AddressInput, CreateProjectInput } from '../API';
import { ProjectStatus } from '../models';
import { LoadingButton } from '../common/LoadingButton';

const initialState = { 
  name: '', 
  clientId: '',
  code: '',
  status: ProjectStatus.ACTIVE,
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  }
} as CreateProjectInput

export default function NewProject() {
  const [redirectId, setRedirectId] = useState<string>('')
  const [project, setProject] = useState<CreateProjectInput>(initialState)
  const [clients, setClients] = useState<any>([])

  useEffect(() => {
    fetchClients()
  }, [])

  async function fetchClients() {
    try {
      const clientData = await API.graphql(graphqlOperation(listClients)) as any
      const clients = clientData.data.listClients.items
      setClients(clients)
    } catch (err) { console.log('error fetching clients') }
  }

  function setProjectValue(key: string, value: string) {
    setProject({ 
      ...project, 
      [key]: value
    })
  }

  function updateAddress(address: AddressInput) {
    setProject({ 
      ...project, 
      address: address
    })
  }


  async function addProject() {
    try {
      if (!project.name) return
      const projectId = uuidv4()
      const projectData = await API.graphql(graphqlOperation(createProject, {input: {
        ...project,
        id: projectId,
        groupId: projectId,
      }})) as any
      setRedirectId(projectData.data.createProject.id)
      setProject(initialState)
    } catch (err) {
      console.log('error creating project:', err)
    }
  }

  if (redirectId !== '') {
    return <Navigate to={`/projects/${redirectId}`} />
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Project
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="client-label">Client</InputLabel>
            <Select
              labelId="client-label"
              onChange={(event: any) => setProjectValue('clientId', event.target.value)}
              label="Client"
              value={project.clientId}
            >
              { clients.map((client: any) => <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Project Name" 
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setProjectValue('name', event.target.value)}
            value={project.name}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Project Id" 
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setProjectValue('code', event.target.value)}
            value={project.code}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Unanet Project Path" 
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setProjectValue('externalId', event.target.value)}
            value={project.externalId}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressEditor address={project.address as AddressInput} editing={true} onChange={updateAddress}/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton 
          variant="contained" 
          color="primary" 
          onClick={addProject}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
