import React from 'react';
import { ColDef, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import { DataGrid } from '../common/DataGrid';

type Props = {
  id: string
  filter?: any
  children?: React.ReactNode
  onSelect?: Function
  height?: any
  columnDefs?: ColDef[]
};

//TODO: https://github.com/aws-amplify/amplify-cli/issues/5121#issuecomment-675568032
const TEMP_LIMIT=500

const searchInspections = /* GraphQL */ `
  query SearchInspections(
    $filter: SearchableInspectionFilterInput
    $sort: [SearchableInspectionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInspections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        project {
          name
          client {
            name
          }
        }
        status
        userId
        user {
          firstName
          lastName
        }
        reviewer {
          firstName
          lastName
        }
        phase {
          name
        }
        inspectionDate
        _deleted
        createdAt
        updatedAt
        number
        code
      }
      nextToken
      total
    }
  }
`;

export const InspectionsGrid: React.FC<Props> = (props) => {
  const {id, filter, children, onSelect, height, columnDefs} = props

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    
    getRows: async function (params: IGetRowsParams) {
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)
      if (searchVariables.filter || filter) {
        searchVariables.filter = {
          ...searchVariables.filter,
          ...filter
        }
      }
      
      if (!searchVariables.sort) {
        searchVariables.sort = {
          "field": "inspectionDate",
          "direction": "desc"
        }
      }

      const inspectionData = await API.graphql(graphqlOperation(searchInspections, {
        ...searchVariables,
        from: 0,
        limit: TEMP_LIMIT,
      })) as any
      const inspections = inspectionData.data.searchInspections.items
      const total = inspectionData.data.searchInspections.total || 0

      params.successCallback(inspections, total);
    }
  }
  
  return (
    <DataGrid id={id} datasource={gridDataSource} onSelect={onSelect} height={height} cacheBlockSize={TEMP_LIMIT} columnDefs={columnDefs}>
      {children}
    </DataGrid>
  )
}
