import React from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { UpdateMilestoneTemplateInput } from '../API';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import DataGrid from '../common/DataGrid';

const searchMilestoneTemplates = /* GraphQL */ `
  query SearchMilestoneTemplates(
    $filter: SearchableMilestoneTemplateFilterInput
    $sort: [SearchableMilestoneTemplateSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMilestoneTemplates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        hierarchyBundleIds
        status
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const MilestoneTemplates = () => {

  const navigate = useNavigate();
  function onRowSelected(item: UpdateMilestoneTemplateInput) {
    navigate(`/milestones/templates/${item.id}`)
  }

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    getRows: async function (params: IGetRowsParams) {
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)

      const templatesData = await API.graphql(graphqlOperation(searchMilestoneTemplates, {
        ...searchVariables,
        from: params.startRow,
      })) as any
      const templates = templatesData.data.searchMilestoneTemplates.items
      const total = templatesData.data.searchMilestoneTemplates.total || 0

      params.successCallback(templates, total);
    },
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Milestone Templates
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/milestones/templates/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            id="milestone-templates"
            datasource={gridDataSource}
            onSelect={onRowSelected}
            columnDefs={[
              {
                field: "name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "status",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </DataGrid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MilestoneTemplates
