import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from '@material-ui/core';

import { InspectionsGrid } from './InspectionsGrid';

type Props = {
  code: string,
  number?: string | null,
  projectId: string,
  phaseId?: string | null,
  open: boolean
  onSave?: (code: string, number?: string) => void
  onCancel?: () => void
};

export const InspectionNumberEditor: React.FC<Props> = (props: Props) => {
  const { code, number, projectId, phaseId, open, onSave, onCancel } = props;

  const [inputCode, setInputCode] = useState<string>('')
  const [inputNumber, setInputNumber] = useState<string>('')
  useEffect(() => {
    if (open) {
      setInputCode(code)
      setInputNumber(number || '')

      let filter: any = {
        projectId: {
          eq: projectId
        }
      }

      if (phaseId) {
        filter = {
          ...filter,
          phaseId: {
            eq: phaseId
          }
        }
      }
      setGridFilter(filter)
    } else {
      setInputCode('')
      setInputNumber('')
    }
  }, [code, number, projectId, phaseId, open])


  const updateCode = (code: string) => {
    setInputCode(code)
    if (code === 'EX') {
      setInputNumber('')
    }
  }

  const updateNumber = (number: string) => {
    setInputNumber(number.replace(/[^0-9]/g, ''))
  }

  const [gridFilter, setGridFilter] = useState<any>()
  useEffect(() => {
    let filter: any = {
      projectId: {
        eq: projectId
      },
      code: {
        eq: inputCode
      }
    }

    if (phaseId) {
      filter = {
        ...filter,
        phaseId: {
          eq: phaseId
        }
      }
    }

    setGridFilter(filter)
  }, [projectId, phaseId, inputCode])

  const cancel = () => {
    onCancel && onCancel()
  }

  const save = () => {
    onSave && onSave(inputCode, inputNumber)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={cancel}
      aria-labelledby="inspection-number-dialog-title"
    >
      <DialogTitle id="inspection-number-dialog-title">Assign Inspection Number</DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Inspection Type"
          value={inputCode}
          onChange={(event) => updateCode(event.target.value)}
          variant="outlined"
          size="small"
          style={{ width: '50%', marginRight: 20 }}
        >
          <MenuItem value="S">Standard</MenuItem>
          <MenuItem value="WT">Speciality - Window Water Test</MenuItem>
          <MenuItem value="MU">Speciality - Mock Up</MenuItem>
          <MenuItem value="AB">Speciality - Air Barrier</MenuItem>
          <MenuItem value="IRT">Speciality - Infrared Thermographic</MenuItem>
          <MenuItem value="EX">Excluded</MenuItem>
        </TextField>
        {inputCode !== 'EX' &&
          <TextField
            label="Inspection Number"
            name="number"
            value={inputNumber}
            onChange={(event) => updateNumber(event.target.value)}
            variant="outlined"
            size="small"
            style={{ width: '30%' }}
          />
        }
        <Typography style={{ marginTop: 40 }}>Inspection History</Typography>
        <InspectionsGrid
          key="inspection-history-grid"
          id="otherInspections"
          height={300}
          filter={gridFilter}
          columnDefs={[
            {
              field: "inspectionDate",
              headerName: "Inspection Date",
              sortable: true,
              cellRenderer: (params: any) => {
                if (params.data && params.data.inspectionDate) {
                  return new Date(params.data.inspectionDate).toLocaleDateString();
                } else {
                  return ''
                }
              }
            },
            {
              field: "phaseId",
              headerName: "Phase",
              cellRenderer: (params: any) => {
                if (params.data && params.data.phase) {
                  return params.data.phase.name
                } else {
                  return ''
                }
              }
            },
            {
              field: "number",
              headerName: "Number",
              sortable: true
            },
            {
              field: "status",
              headerName: "Status",
              sortable: true
            }
          ]}>
        </InspectionsGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={save} color="primary" disabled={!(inputCode && (inputNumber || inputCode === 'EX'))}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InspectionNumberEditor