import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { CreateExternalLinkInput, UpdateExternalLinkInput } from "../API";
import { createExternalLink, deleteExternalLink } from "../graphql/mutations";
import { externalLinksByProjectId } from "../graphql/queries";
import { ExternalLink } from "./ExternalLink";
import { ExternalLinkEditor } from "./ExternalLinkEditor";

export const ExternalLinks: React.FC<{projectId: string}> = ({projectId}) => {
    const [externalLinks, setExternalLinks] = useImmer<Array<CreateExternalLinkInput | UpdateExternalLinkInput>>([])
    const [addLink, setAddLink] = useState<boolean>()

    const fetchExternalLinks = useCallback(async () => { 
        const externalLinksData = await API.graphql(graphqlOperation(externalLinksByProjectId, { projectId: projectId })) as any
        const externalLinks = externalLinksData.data.externalLinksByProjectId.items.filter((i: any) => !i._deleted)
        
        setExternalLinks(draft => {
          draft.splice(0, draft.length)
          draft.push(...externalLinks)
        })
    }, [projectId, setExternalLinks])
    
    useEffect(() => {
        fetchExternalLinks()
    }, [fetchExternalLinks])

    async function addExternalLink(changes: any) {
        const linkData = await API.graphql(graphqlOperation(createExternalLink, { input: {
            groupId: projectId,
            projectId: projectId,
            ...changes 
        }})) as any
        const link = linkData.data.createExternalLink
        
        setExternalLinks(draft => {
            draft.push(link)
        })
        setAddLink(false)
    }

    function onSave(item: UpdateExternalLinkInput) {
        
        const idx = externalLinks.findIndex(l => l.id === item.id)
        if (idx !== -1) {
            setExternalLinks(draft => {
                draft[idx] = item
            })
        }
    }

    async function removeExternalLink(item: UpdateExternalLinkInput) {
        if (!item) return
    
        try {
            await API.graphql(graphqlOperation(deleteExternalLink, {input: {
              id: item.id,
              _version: item._version
            }}))

            setExternalLinks(draft => {
                const idx = externalLinks.indexOf(item)
                draft.splice(idx, 1)
            })
        } catch (err) {
          console.log('error deleting external link:', err)
        }
    }

    return (
        <Card variant="outlined">
            <CardHeader style={{ backgroundColor: 'silver' }}
            title='External Links'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
                <IconButton style={{ margin: 0 }} size="small" onClick={() => setAddLink(true)}>
                <Add />
                </IconButton>
            }
            />
            <CardContent>
            { addLink && 
                <ExternalLinkEditor 
                    item = {{
                        title: '',
                        url: '',
                        projectId: projectId
                    } as CreateExternalLinkInput}
                    onSave={addExternalLink} 
                    onCancel={() => 
                    setAddLink(false)} 
                />
            }
            {externalLinks.map(link => 
                <ExternalLink key={link.id} item={link} onSave={onSave} onRemove={removeExternalLink} />
            )}
            </CardContent>
        </Card>
    )
}