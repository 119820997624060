import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { Link, useNavigate } from 'react-router-dom';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import { DataGrid } from '../common/DataGrid';
import { ProjectStatus } from '../models';

export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: [SearchableClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        code
        status
        createdAt
        updatedAt
        _deleted
        projects {
          items {
            id
            status
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const Clients = () => {

  const navigate = useNavigate();
  function onRowSelected(item: any) {
    navigate(`/clients/${item.id}`)
  }

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    getRows: async function (params: IGetRowsParams) {
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)

      const clientData = await API.graphql(graphqlOperation(searchClients, {
        ...searchVariables,
        from: params.startRow,
      })) as any
      const clients = clientData.data.searchClients.items
      const total = clientData.data.searchClients.total || 0

      params.successCallback(clients, total);
    },
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Clients
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/clients/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            id="clients"
            datasource={gridDataSource}
            onSelect={onRowSelected}
            columnDefs={[
              {
                field: "code",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                headerName: "Total Projects",
                cellRenderer: (params: any) => {
                  if (params.data) {
                    return params.data.projects.items.length
                  } else {
                    return null
                  }
                }
              },
              {
                headerName: "Active Projects",
                cellRenderer: (params: any) => {
                  if (params.data) {
                    return params.data.projects.items.filter((p: any) => p.status === ProjectStatus.ACTIVE).length
                  } else {
                    return null
                  }
                }
              },
              {
                field: "status",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </DataGrid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Clients
