import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ColDef, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import { DataGrid } from '../common/DataGrid';

type Props = {
  id: string,
  filter?: any
  children?: React.ReactNode
  onSelect?: Function
  columnDefs?: ColDef[]
};

export const ProjectsGrid: React.FC<Props> = (props) => {
  const {id, filter, children, onSelect, columnDefs} = props

  const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        code
        status
        clientId
        createdAt
        updatedAt
        client {
          name
        }
        address {
          city
          state
        }
        eft {
          firstName
          lastName
        }
      }
      nextToken
      total
    }
  }
`;

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    getRows: async function (params: IGetRowsParams) {
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)
      if (searchVariables.filter || filter) {
        searchVariables.filter = {
          ...searchVariables.filter,
          ...filter
        }
      }
      const projectData = await API.graphql(graphqlOperation(searchProjects, {
        ...searchVariables,
        from: params.startRow,
      })) as any
      const projects = projectData.data.searchProjects.items
      const total = projectData.data.searchProjects.total || 0

      params.successCallback(projects, total);
    }
  }

  return (
    <DataGrid id={id} datasource={gridDataSource} onSelect={onSelect} columnDefs={columnDefs}>
      {children}
    </DataGrid>
  )
}

export default ProjectsGrid