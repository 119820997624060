import React from 'react';
import { Routes, Route, Link, useMatch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import Dashboard from './dashboard/Dashboard';
import Inspections from './inspection/Inspections';
import Projects from './project/Projects';
import Clients from './client/Clients';
import Team from './team/Team';
import NewClient from './client/NewClient';
import ClientView from './client/ClientView';
import NewProject from './project/NewProject';
import NewInspection from './inspection/NewInspection';
import ProjectView from './project/ProjectView';
import { Contacts } from './contact/Contacts';
import { NewContact } from './contact/NewContact';
import ContactView from './contact/ContactView';
import HierarchyView from './hierarchy/HierarchyView';
import Logo from './assets/criterium-triangle.svg';
import { useTheme, View, withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { NewUser } from './team/NewUser';
import { UserView } from './team/UserView';
import PhaseView from './phase/PhaseView';
import { NewPhase } from './phase/NewPhase';
import InspectionView from './inspection/InspectionView';
import MilestoneTemplates from './milestone/MilestoneTemplates';
import MilestoneBundles from './milestone/MilestoneBundles';
import { NewMilestoneBundle } from './milestone/NewMilestoneBundle';
import MilestoneBundleView from './milestone/MilestoneBundleView';
import { NewMilestoneTemplate } from './milestone/NewMilestoneTemplate';
import MilestoneTemplateView from './milestone/MilestoneTemplateView';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: '#333333'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      paddingTop: 80,
      background: '#2143AB',
      color: '#ffffff'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    navLink: {
      color: '#ffffff',
      fontWeight: 500,
      textDecoration: 'none'
    },
    navLinkSelected: {
      backgroundColor: '#305EEC',
      '&:hover': {
        backgroundColor: '#305EEC'
      },
      '& $navLink': {
        fontWeight: 'bold'
      }
    }
  }),
);

function NavLink({ label, to, activeOnlyWhenExact }: any) {
  const classes = useStyles();

  let match = useMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
    <Link className={classes.navLink} to={to}>
      <ListItem button style={{ paddingLeft: 20 }} classes={{ selected: classes.navLinkSelected }} selected={match ? true : false} key="path">
        <ListItemText primaryTypographyProps={{ classes: { root: classes.navLink} }} primary={label} />
      </ListItem>
    </Link>
  );
}

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, signOut }: Props) {
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={signOut}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div style={{ display: 'flex'}}>
            <img src={Logo} alt="" height={40} />
            <Typography style={{ marginLeft: 10, fontWeight: 'bold' }} variant="h4">
              CRISP
            </Typography>
          </div>
          <div className={classes.grow} />
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          { false && <NavLink label="Dashboard" to="/" activeOnlyWhenExact={true} /> }
          <NavLink label="Dashboard" to="/dashboard" />
          <NavLink label="Inspections" to="/inspections" />
          <NavLink label="Projects" to="/projects" />
          <NavLink label="Clients" to="/clients" />
          <NavLink label="Contacts" to="/contacts" />
          <NavLink label="Team" to="/team" />
          <NavLink label="Hierarchy" to="/hierarchy" />
          <ListItem>
            <ListItemText style={{ paddingLeft: 5 }} primary="Milestones" />
          </ListItem>
          <div style={{ paddingLeft: 20 }}>
            <NavLink label="Bundles" to="/milestones/bundles" />
          </div>
          <div style={{ paddingLeft: 20 }}>
            <NavLink label="Templates" to="/milestones/templates" />
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Routes>
          <Route 
            path="/"
            element={<Dashboard/>}
          />
          <Route 
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route 
            path="/inspections/*"
            element={<Inspections />}
          />
          <Route 
            path="/newInspection"
            element={<NewInspection />}
          />
          <Route 
            path="/inspection/:id/*"
            element={<InspectionView />}
          />
          <Route 
            path="/projects"
            element={<Projects />}
          />
          <Route 
            path="/projects/new"
            element={<NewProject />}
          />
          <Route 
            path="/projects/:id"
            element={<ProjectView />}
          />
          <Route 
            path="/projects/:id/phase"
            element={<NewPhase />}
          />
          <Route 
            path="/clients"
            element={<Clients />}
          />
          <Route 
            path="/clients/new"
            element={<NewClient />}
          />
          <Route 
            path="/clients/:id"
            element={<ClientView />}
          />
          <Route 
            path="/contacts"
            element={<Contacts />}
          />
          <Route 
            path="/contacts/new"
            element={<NewContact />}
          />
          <Route 
            path="/contacts/:id"
            element={<ContactView />}
          />
          <Route 
            path="/team"
            element={<Team />}
          />
          <Route 
            path="/team/new"
            element={<NewUser />}
          />
          <Route 
            path="/team/:id"
            element={<UserView />}
          />
          <Route 
            path="/hierarchy"
            element={<HierarchyView />}
          />
          <Route 
            path="/phases/:id"
            element={<PhaseView />}
          />
          <Route 
            path="/milestones/templates"
            element={<MilestoneTemplates />}
          />
          <Route 
            path="/milestones/templates/new"
            element={<NewMilestoneTemplate />}
          />
          <Route 
            path="/milestones/templates/:id"
            element={<MilestoneTemplateView />}
          />
          <Route 
            path="/milestones/bundles"
            element={<MilestoneBundles />}
          />
          <Route 
            path="/milestones/bundles/new"
            element={<NewMilestoneBundle />}
          />
          <Route 
            path="/milestones/bundles/:id"
            element={<MilestoneBundleView />}
          />
        </Routes>
      </main>
    </div>
  );
}
const components = {
  SignIn: {
Header() {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <View display="flex" flexDirection="row" alignItems="center" >
        <View display="flex">
          <img src={Logo} height={60} alt="" />
        </View>
        <Box style={{ display: "flex", flexDirection: "column", marginLeft: 10, alignItems: "flex-start"}} >
          <div style={{ lineHeight: 1, color: '#2D3142', fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }}>
            CRISP
          </div>
          <div style={{ lineHeight: 1, color: '#2D3142', fontSize: 14, textTransform: 'uppercase' }}>
            by Criterium Engineers
          </div>
        </Box>
      </View>
    </View>
  );
    }
  }
}

export default withAuthenticator(App, {hideSignUp: true, components: components});

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
