import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createMilestoneTemplate } from '../graphql/mutations';
import { Grid, TextField, Typography } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { CreateMilestoneTemplateInput, UpdateHierarchyBundleInput } from '../API';
import { HierarchyBundleStatus, HierarchyBundleType, MilestoneTemplateStatus } from '../models';
import { LoadingButton } from '../common/LoadingButton';
import Select from 'react-select';
import { hierarchyBundlesByTypeAndStatus } from '../graphql/queries';

export const NewMilestoneTemplate = () => {

  const navigate = useNavigate();
  const [template, setTemplate] = useState<CreateMilestoneTemplateInput>({
    name: '',
    status: MilestoneTemplateStatus.ACTIVE
  })

  const [bundles, setBundles] = useState<any>([])

  useEffect(() => {
    fetchBundles()
  }, [])

  async function fetchBundles() {
    try {
      const bundlesData = await API.graphql(graphqlOperation(hierarchyBundlesByTypeAndStatus, {
        type: HierarchyBundleType.MILESTONE,
        status: {
          eq: HierarchyBundleStatus.ACTIVE
        }
      })) as any
      const bundles = bundlesData.data.hierarchyBundlesByTypeAndStatus.items
      setBundles(bundles.map((bundle: UpdateHierarchyBundleInput) => {
        return {
          value: bundle.id,
          label: bundle.name
        }
      }))
    } catch (err) { console.log('error fetching bundles') }
  }

  function setTemplateValue(key: string, value: any) {
    setTemplate({ 
      ...template, 
      [key]: value
    })
  }

  const [selectedBundles, setSelectedBundles] = useState<any[]>([])
  const onBundleChange = (value: any) => {
    setSelectedBundles(value)
    setTemplateValue('hierarchyBundleIds', JSON.stringify(value.map((v: any) => v.value)))
  };

  async function addTemplate() {
    try {
      if (!template.name) return
      const templateData = await API.graphql(graphqlOperation(createMilestoneTemplate, {input: template})) as any
      const newTemplate = templateData.data.createMilestoneTemplate
      navigate(`/milestones/templates/${newTemplate.id}`)
    } catch (err) {
      console.log('error creating template:', err)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Milestone Template
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <TextField 
            label="Milestone Template Name" 
            variant="outlined"
            size="small"
            fullWidth
            name="name"
            onChange={(event) => setTemplateValue(event.target.name, event.target.value)}
            value={template.name}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          Milestone Bundles
        </Typography>
        <Select
          isMulti
          name="hierarchyBundleIds"
          onChange={onBundleChange}
          options={bundles}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Bundles"
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton 
          variant="contained" 
          color="primary" 
          onClick={addTemplate}
          disabled={!(template.name && selectedBundles.length)}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
