import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper, Tab, Tabs, Button } from '@material-ui/core';
import { InspectionsGrid } from './InspectionsGrid';
import { UpdateInspectionInput } from '../API';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { InspectionStatus } from '../models';
import { useCurrentUser } from '../hooks/AuthHooks';

export const Inspections = () => {
  const { pathname } = useLocation();
  const user = useCurrentUser()

  const navigate = useNavigate();

  const [tabs, setTabs] = useState<any[]>([])
  useEffect(() => {
    setTabs([
      { label: "All", url: "/inspections" },
      { label: "Active", url: "/inspections/active", filter: { status: { eq: InspectionStatus.ACTIVE } } },
      { label: "Submitted", url: "/inspections/submitted", filter: { status: { eq: InspectionStatus.SUBMITTED } } },
      {
        label: "Reviewing", url: "/inspections/reviewing", filter: {
          or: [
            { status: { eq: InspectionStatus.REVISING } },
            { status: { eq: InspectionStatus.REVIEWING } }
          ]
        }
      },
      { label: "Declined", url: "/inspections/Declined", filter: { status: { eq: InspectionStatus.DECLINED } } },
      { label: "Approved", url: "/inspections/approved", filter: { status: { eq: InspectionStatus.APPROVED } } },
      { label: "Sent", url: "/inspections/sent", filter: { status: { eq: InspectionStatus.SENT } } }
    ])
  }, [pathname, user])

  const [selectedTab, setSelectedTab] = useState<string | false>(false)
  useEffect(() => {
    const selected = tabs.find(t => t.url === pathname)
    setSelectedTab(selected?.url || false)
    setStatusFilter(selected?.filter)
  }, [tabs, pathname])

  const handleCallToRouter = (event: React.ChangeEvent<{}>, value: string) => {
    navigate(value);
  }

  const [statusFilter, setStatusFilter] = useState<any>()

  function onSelect(item: UpdateInspectionInput) {
    navigate(`/inspection/${item.id}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Inspections
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/newInspection" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={selectedTab}
          onChange={handleCallToRouter}
        >
          {tabs.map(t => <Tab key={t.label} label={t.label} value={t.url} />)}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <InspectionsGrid
            id="inspections"
            filter={statusFilter}
            onSelect={onSelect}
            columnDefs={[
              {
                field: "projectId",
                headerName: "Project",
                filter: "projectFilter",
                cellRenderer: (params: any) => {
                  if (params.data) {
                    return params.data.project.name
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "phaseId",
                headerName: "Phase",
                sortable: true,
                cellRenderer: (params: any) => {
                  if (params.data && params.data.phase) {
                    return params.data.phase.name
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "number",
                headerName: "Number",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.number) {
                    if (params.data.code === 'S') {
                      return params.data.number;
                    }
                    return `${params.data.code}-${params.data.number}`;
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "inspectionDate",
                headerName: "Inspection Date",
                sortable: true,
                filter: true,
                cellRenderer: (params: any) => {
                  if (params.data && params.data.inspectionDate) {
                    return new Date(params.data.inspectionDate).toLocaleDateString();
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "inspectionReviewerId",
                headerName: "Reviewing Engineer",
                filter: "reviewerFilter",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.reviewer) {
                    return params.data.reviewer.firstName + ' ' + params.data.reviewer.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "user",
                headerName: "EFT",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.user) {
                    return params.data.user.firstName + ' ' + params.data.user.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "status",
                headerName: "Status",
                sortable: true
              }
            ]}>
          </InspectionsGrid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Inspections
