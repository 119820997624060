import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  List,
  ListItem, 
  Grid, 
  Switch, 
  Typography, 
  Box, 
  TextField, 
  FormControlLabel, 
  Button, 
  Fab, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Radio, 
  RadioGroup 
  } from '@material-ui/core';
import { 
  hierarchyItemsByHierarchyId,
  listHierarchies,
  listInspectionPoints
  } 
  from '../graphql/queries';
import { searchInspectionPoints } from '../graphql/custom_queries'
import { 
  createInspectionPoint,
  createObservation, 
  createFinding, 
  createLocation, 
  updateObservation 
} from '../graphql/mutations'
import { findingsByProjectId } from '../graphql/custom_queries';
import { API, graphqlOperation } from 'aws-amplify';
import { 
  CreateInspectionPointInput, 
  InspectionPointType, 
  UpdateInspectionPointInput,
  CreateObservationInput,
  CreateLocationInput, 
  ObservationType,
  FindingType, 
  CreateFindingInput, 
  FindingStatus,
  UpdateObservationInput 
} from '../API'
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import TreeItem from '@material-ui/lab/TreeItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useNavigate } from 'react-router-dom';
import { ObservationStatus } from '../models';
import { InspectionContext } from './InspectionContext';

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
  formControlLabel: {
    margin: 5,
  },
  fabButton: {
    maxHeight: 20,
    minHeight: 20,
    maxWidth: 20,
    minWidth: 20,
    alignSelf: "flex-end",
    marginBottom: 2,
    marginRight: 2
  },
  title: {
    textAlign: 'center',
    paddingTop: 40,
    paddingBottom: 10
  },
  reviewItem: {
    fontStyle: 'bold'
  },
});

function getSteps() {
  return ['Select Inspection Point', 'Select Observation Type', 'Create Observation'];
}

const arrayToTree = (list: any) => {
  const map: number[] = [];
  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  let node
  const roots: any = [];

  for (const item of list) {
    node = item
    if (node.parentId !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') {
      if (list[map[node.parentId]] !== undefined) {
        list[map[node.parentId]].children?.push({ 'id': node.id, 'code': `${node.code} ${node.name}`, 'inspectionPoints': node.inspectionPoints, 'children': node.children });
      }
    } else {
      roots.push({ 'id': node.id, 'code': `${node.code} ${node.name}`,'inspectionPoints': node.inspectionPoints, 'children': node.children });
    }
  }
  return { 'id': '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f', 'code': 'CSI Hierarchy', 'inspectionPoints': [], 'children': roots }
}

const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

type Props = {
  handleClose: Function,
};

export const NewObservation: React.FC<Props> = ({ handleClose }) => {

  const { inspection } = useContext(InspectionContext);
  const navigate = useNavigate()
  const classes = useStyles();
  const [hierarchyItems, setHierarchyItems] = useState<any[]>([])
  const [hierarchyItemsMap, setHierarchyItemsMap] = useState<any>()
  // const [hierarchy, setHierarchy] = useState<UpdateHierarchyInput | CreateHierarchyInput>()
  const [ipText, setIpText] = useState<string>()
  const [checked, setChecked] = useState('');
  const [scoped, setScoped] = useState<boolean>(true)
  const [addInspectionPoint, setAddInspectionPoint] = useState<boolean>(false)
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const fetchHierarchyItems = useCallback(async(hierarchyId: string = '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') => {
    const inspectionPointsData = await API.graphql(graphqlOperation(listInspectionPoints, {
      filter: {or: [{projectId: {eq: inspection?.projectId}}, {type: {eq: InspectionPointType.APPROVED}}]},
      limit: 5000
    })) as any
    const inspectionPoints = inspectionPointsData.data.listInspectionPoints.items
    const hierarchyItemsData = await API.graphql(graphqlOperation(hierarchyItemsByHierarchyId, {
      hierarchyId: hierarchyId, limit: 1200
    })) as any
    const hierarchyItems = hierarchyItemsData.data.hierarchyItemsByHierarchyId.items
      .filter((i: any) => !i._deleted)
      .sort((a: any, b: any) => a.code > b.code ? 1 : -1)
    hierarchyItems.forEach((i: any) => i.inspectionPoints = inspectionPoints.filter((p: any) => p.hierarchyItemId === i.id))
    const map = Object.fromEntries(hierarchyItems.map((h: any) => [h.id, h]))
    setHierarchyItems(hierarchyItems)
    setHierarchyItemsMap(map)
  }, [inspection])

  const fetchHierarchy = useCallback(async () => {
    const hierarchyData = await API.graphql(graphqlOperation(listHierarchies)) as any
    const hierarchies = hierarchyData.data.listHierarchies.items
    if (hierarchies.length) {
      // setHierarchy(hierarchies[0])
      await fetchHierarchyItems(hierarchies[0].id);
    } else {
     // setHierarchy({
     //   name: ''
     // })
    }
  }, [fetchHierarchyItems])

  useEffect(() => {
    fetchHierarchy()
  }, [fetchHierarchy])

  const handleToggle = (ip: any, node: any) => () => {
    setChecked(ip.id)
    setSelectedInspectionPoint(ip)
    setSelectedHierarchyItem(node)
  };

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };

  const searchChange = async (value: string) => {
    if (value !== "") {
      const inspectionPointsData = await API.graphql(graphqlOperation(searchInspectionPoints, {
        filter: { text: { regexp: `.*?${value}.*` }}
      })) as any
      const inspectionPoints = inspectionPointsData.data.searchInspectionPoints.items.filter((ip: any) => ip.type === InspectionPointType.APPROVED || ip.projectId === inspection?.projectId)
      hierarchyItems.forEach((i: any) => i.inspectionPoints = inspectionPoints.filter((p: any) => p.hierarchyItemId === i.id))
      setHierarchyItems(hierarchyItems)
      const allParentNodes: any = ['3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f']
      // Open up all parent nodes.
      inspectionPoints.forEach(
        (i: any) => {
          let a = hierarchyItemsMap[i.hierarchyItemId]
          while (a) {
            allParentNodes.unshift(a.id)
            a = hierarchyItemsMap[a.parentId] 
          }
          
        }
      )
      setExpandedNodes(allParentNodes)
    }
  }

  const handleAddIP = (e: any, node: string) => {
    e.preventDefault()
    setAddInspectionPoint(true)
    setSelectedHierarchyItem(node)
  }

  const newInspectionPoint = async (e: any) => {
    let ip: UpdateInspectionPointInput;
    const hierarchyItemData = await API.graphql(graphqlOperation(createInspectionPoint, {
      input: {
        type: InspectionPointType.SUBMITTED,
        hierarchyItemId: selectedHierarchyItem,
        projectId: inspection?.projectId,
        groupId: inspection!.projectId,
        text: ipText
      } as CreateInspectionPointInput
    })) as any
    ip = hierarchyItemData.data.createInspectionPoint
    // Update hierarchy item to rerender tree.
    const hiIndex = hierarchyItems?.findIndex((i: any) => i.id === selectedHierarchyItem)
    const updatedObj = { ...hierarchyItems[hiIndex], 
          inspectionPoints: hierarchyItems[hiIndex].inspectionPoints ? hierarchyItems[hiIndex].inspectionPoints.concat(ip) : []};
    const updatedHierarchyItems = [
      ...hierarchyItems.slice(0, hiIndex),
      updatedObj,
      ...hierarchyItems.slice(hiIndex + 1),
    ];
    setHierarchyItems(updatedHierarchyItems)
    setExpandedNodes(expandedNodes.concat(selectedHierarchyItem!))
    setAddInspectionPoint(false)
  }

  const [type, setType] = useState<FindingType | null>(null)
  const [selectedHierarchyItem, setSelectedHierarchyItem] = useState<any>()
  const [selectedInspectionPoint, setSelectedInspectionPoint] = useState<any>()

  const addObservation = async () => {


    const observationData = await API.graphql(graphqlOperation(createObservation, {
      input: {
        projectId: inspection?.projectId,
        groupId: inspection!.groupId || inspection!.projectId,
        inspectionId: inspection?.id,
        hierarchyItemId: selectedHierarchyItem.id,
        inspectionPointId: selectedInspectionPoint.id,
        status: ObservationStatus.NEW,
      } as CreateObservationInput
    })) as any

    const observation = observationData.data.createObservation

    const primaryLocationData = await API.graphql(graphqlOperation(createLocation, {
      input: {
        groupId: inspection!.groupId || inspection!.projectId,
        observationId: observation.id,
        projectId: inspection?.projectId,
      } as CreateLocationInput
    })) as any

    const primaryLocation = primaryLocationData.data.createLocation

    if (type === FindingType.IOC || type === FindingType.NCI) {
      // Determine value for finding code.
      const findingsData = await API.graphql(graphqlOperation(findingsByProjectId, {
        projectId: inspection?.projectId, limit: 1000
      })) as any
      let rawInspection = inspection as any
      const codeNumber = Math.max(...findingsData.data.findingsByProjectId.items.map((f: any) => f.code ? parseInt(f.code.split('-').slice(-1)) : null))
      const code = codeNumber ? `${rawInspection!.project.code}-${codeNumber + 1}` : `${rawInspection!.project.code}-1`
      const findingData = await API.graphql(graphqlOperation(createFinding, {
        input: {
          type: type,
          code: code,
          date: inspection?.inspectionDate,
          projectId: inspection?.projectId,
          groupId: inspection!.groupId || inspection!.projectId,
          inspectionId: inspection?.id,
          phaseId: inspection?.phaseId,
          hierarchyItemId: selectedHierarchyItem.id,
          observationId: observation.id,
          status: FindingStatus.NEW
        } as CreateFindingInput
      })) as any

      const finding = findingData.data.createFinding
      await API.graphql(graphqlOperation(updateObservation, {
        input: {
          type: ObservationType.FINDING,
          groupId: finding!.groupId || finding!.projectId,
          id: observation.id,
          observationPrimaryLocationId: primaryLocation.id,
          findingId: finding.id
        } as UpdateObservationInput
      }))
    } else {
      await API.graphql(graphqlOperation(updateObservation, {
        input: {
          type: ObservationType.CONFORMING,
          groupId: inspection!.groupId || inspection!.projectId,
          id: observation.id,
          observationPrimaryLocationId: primaryLocation.id,
        } as UpdateObservationInput
      }))
    }
    navigate(`${observation.id}`)
  }

  const renderTree = (n: any) => (
    <TreeItem 
      id={n.id}
      key={n.id}
      nodeId={n.id}
      label={<Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
               <Typography>{n.code}</Typography>
               { n.id !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f' &&
                 <Fab onClick={(event: any) => handleAddIP(event, n.id)} className={classes.fabButton} size="small" color="primary" aria-label="add"><AddIcon /></Fab>
               }
               </Box>
            }
    >
      { n.inspectionPoints.length > 0 ? 
          n.inspectionPoints.map((element: any) => 
          <TreeItem nodeId={element.id} key={element.id}
          label={
            <FormControlLabel 
              control={
              <Checkbox 
                checked={element.id === checked}
                onClick={handleToggle(element, n)}  
              />
              } 
              label={<Typography>{element.text}</Typography>}
              className={classes.formControlLabel}
            />
          }
          />
           )
          
      : null}
      {Array.isArray(n.children) ? n.children.map((blah: any) => renderTree(blah)) : null}
    </TreeItem>
  );

  const handleNext = () => {

    if (activeStep === steps.length - 1) {
      // Create observation
      addObservation()
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as any).value);
  };

  return (
    <div>
        <Box className={classes.title}>
          <Typography variant="h4">Create New Observation</Typography>
        </Box>
        <Box width="66%" alignSelf={'center'}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
          {activeStep === steps.length ? (
            <div>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  disabled={(activeStep === 0 && !selectedInspectionPoint) || (activeStep === 1 && !type)}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button onClick={() => handleClose()} color="secondary">Cancel</Button>
              </div>
            </div>
          )}
        </div>
        <Box marginTop={10}>
          {activeStep === 0 &&
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Switch
                    checked={scoped}
                    onChange={() => setScoped(!scoped)}
                    color="primary"
                    name="scoped"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography>Scope to Inspection</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField onChange={debounce((event: any) => searchChange(event.target.value), 1000)} label="Search..." variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expandedNodes}
                  onNodeToggle={handleNodeToggle}
                >
                  {hierarchyItems ? renderTree(arrayToTree(scoped ? hierarchyItems.filter((n) => inspection?.scope?.includes(n.id)) : hierarchyItems)) : null}
                </TreeView>
              </Grid>
            </Grid>
          }
          {activeStep === 1 &&
              <FormControl component="fieldset">
                <FormLabel component="legend">Observation Type</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={type} onChange={handleTypeChange}>
                  <FormControlLabel value={FindingType.IOC} control={<Radio />} label="Item of Concern" />
                  <FormControlLabel value={FindingType.NCI} control={<Radio />} label="Non Conforming Item" />
                  <FormControlLabel value="Conforming" control={<Radio />} label="Conforming" />
                </RadioGroup>
              </FormControl>
            }
            {activeStep === 2 &&
              <Box>
                <Typography variant='h4'>Review</Typography>
                <List>
                  <ListItem>
                    <Typography variant='h6'>CSI Item: {selectedHierarchyItem.code}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant='h6'>Inspection Point: {selectedInspectionPoint.text}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant='h6'>Observation Type: {type}</Typography>
                  </ListItem>
                </List>
              </Box>

            }
          </Box>
        </Box>
      <Dialog open={addInspectionPoint}>
        <DialogTitle id="form-dialog-title">Add Inspection Point</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e: any) => setIpText(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Inspection Point Text..."
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddInspectionPoint(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => newInspectionPoint(e)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
