import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { UpdateLocationPhotoInput, UpdatePhotoInput } from '../API';
import {
  createPhoto,
  createLocationPhoto,
  deleteLocationPhoto,
  deletePhoto as deleteUpstreamPhoto,
  updateLocation,
  updatePhoto
} from '../graphql/mutations';
import { locationTagsByLocationId } from '../graphql/queries';
import {
  createTag,
  createLocationTag,
  updateTag,
  deleteLocationTag
} from '../graphql/mutations';
import { getLocation } from '../graphql/custom_queries';
import { LocationPhotoItem } from './LocationPhotoItem';
import { PhotoItem } from './PhotoItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import awsconfig from '../aws-exports'
import { LoadingButton } from '../common/LoadingButton';

const grid = 1;

const getItemStyle = (isDragging: boolean, draggableStyle: any, item: any) => ({
  userSelect: 'none',
  padding: grid,
  margin: `0 ${grid}px 0 0`,

  // change background color if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

type Props = {
  id: string,
  annotatorColor: string,
  editable?: boolean,
  primaryLocation: boolean,
  changePrimaryLocation: Function,
  inspectionId?: string,
  clientId?: string,
  canUpload: boolean,
  doneUploading: Function,
  projectTags: any,
  fetchProjectTags: any,
  tagsChanged: boolean,
  changeTags: Function,
  updateReordering: Function,
  reordering: boolean,
  cancelled: boolean,
  warn: Function
};

export const ObservationLocation: React.FC<Props> = (props) => {
  const {
    id,
    annotatorColor,
    editable,
    inspectionId,
    clientId,
    canUpload,
    doneUploading,
    primaryLocation,
    changePrimaryLocation,
    projectTags,
    fetchProjectTags,
    tagsChanged,
    changeTags,
    updateReordering,
    reordering,
    cancelled,
    warn
  } = props

  const [location, setLocation] = useState<any>()
  const [locationPhotos, setLocationPhotos] = useState<UpdateLocationPhotoInput[]>([])
  const [progress, setProgress] = useState(false)
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [uploadMsg, setUploadMsg] = useState<string>('')
  const [tagToEdit, setTagToEdit] = useState<any>({ text: '', id: null })
  const [dialogMsg, setDialogMsg] = useState<string>('')
  const [dialogBtnTxt, setDialogBtnTxt] = useState<string>('')
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [addTagValue, setAddTagValue] = useState<any>()

  const setPrimaryPhoto = async (item: UpdateLocationPhotoInput) => {
    warn(`Primary photo set on location ${location.id}`)
    const locationData = await API.graphql(graphqlOperation(updateLocation, {
      input: {
        id: location.id,
        _version: location._version,
        locationPrimaryPhotoId: item.photoId
      }
    })) as any
    const updatedLocation = locationData.data.updateLocation
    setLocation(updatedLocation)
    doneUploading()
  }

  const fetchLocation = useCallback(async () => {
    const locationData = await API.graphql(graphqlOperation(getLocation, { id: id })) as any
    const location = locationData.data.getLocation
    if (!location.photoSequence) {
      location.photoSequence = []
    }
    if (location.photos.items.length && !location.primaryPhoto) {
      await API.graphql(graphqlOperation(updateLocation, { input: { id: location.id, _version: location._version, locationPrimaryPhotoId: location.photos.items[0].photo.id } }))
      location.primaryPhoto = location.photos.items[0].photo
    }
    setLocation(location)
    setLocationPhotos(location.photos.items.filter((i: any) => !i._deleted && (i.photoId !== (location.primaryPhoto ? location.primaryPhoto.id : null))).sort((a: any, b: any) => 
    {
      const aIdx = location.photoSequence.indexOf(a.id)
      const bIdx = location.photoSequence.indexOf(b.id)
    
      if (aIdx < 0 && bIdx < 0) {
        if (a.createdAt < b.createdAt) {
          return -1
        } else if (a.createdAt > b.createdAt) {
          return 1
        } else {
          return 0
        }
      } else if (bIdx < 0) {
        return -1
      } else if (aIdx < 0) {
        return 1
      } else {
        return aIdx - bIdx
      }
    }
    ))
  }, [id])

  const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setUploadMsg('')
  };

  useEffect(() => {
    fetchLocation()
  }, [fetchLocation, tagsChanged, cancelled])

  const [locationTags, setLocationTags] = useState<any[]>([])
  const fetchLocationTags = useCallback(async () => {
    const locationTagsData = await API.graphql(graphqlOperation(locationTagsByLocationId, { locationId: id })) as any
    const locationTags = locationTagsData.data.locationTagsByLocationId.items
      .filter((i: any) => !i._deleted) as any[]
    setLocationTags(locationTags)
  }, [id])


  useEffect(() => {
    fetchLocationTags()
  }, [fetchLocationTags, tagsChanged])


  const deletePhoto = async (item: UpdateLocationPhotoInput) => {
    warn(`Photo deleted on location ${location.id}`)
    const deleteIdx = locationPhotos.findIndex(p => p.id === item.id)
    const rawItem: any = { ...item };
    await API.graphql(graphqlOperation(deleteUpstreamPhoto, { input: { id: item.photoId!, _version: rawItem.photo._version } }))
    await API.graphql(graphqlOperation(deleteLocationPhoto, { input: { id: item.id, _version: item._version } }))
    const photos = [...locationPhotos]
    photos.splice(deleteIdx, 1)
    setLocationPhotos([
      ...photos
    ])
    doneUploading()
  }

  const editTag = async (tag: any) => {
    warn(`Tag changed on location ${location?.id}`)
    await API.graphql(graphqlOperation(updateTag, { input: { id: tag.id, _version: tag._version, text: tag.text } }))
    setTagToEdit({})
    fetchProjectTags()
    fetchLocationTags()
    changeTags()
    doneUploading()
  }

  const addTag = async (tag: any) => {
    if (tag.id) {
      await API.graphql(graphqlOperation(createLocationTag, { input: {
        groupId: location.groupId || location.projectId,
        locationId: location.id,
        tagId: tag.id,
        projectId: location.projectId  
      }}))
    } else {
      const tagData = await API.graphql(graphqlOperation(createTag, { input: { 
        groupId: location.groupId || location.projectId,
        type: 'LOCATION',
        projectId: location.projectId,
        text: tag
      }})) as any
      await API.graphql(graphqlOperation(createLocationTag, { input: { 
        groupId: location.groupId || location.projectId,
        locationId: location.id,
        tagId: tagData.data.createTag.id,
        projectId: location.projectId
      }}))

    }
    warn(`Tag added on location ${location.id}`)
    setAddTagValue(null)
    fetchLocationTags()
    fetchProjectTags()
    doneUploading()
  }

  async function onChange(e: any) {
    warn(`Photo(s) added on location ${location.id}`)
    const bucket = awsconfig.aws_user_files_s3_bucket;
    const region = awsconfig.aws_user_files_s3_bucket_region;
    const uploadPhoto: any = async (file: File) => {
      const ext = file!.name!.toLowerCase().split('.').pop()
      if (!['jpeg', 'jpg', 'png'].includes(ext ? ext : '')) {
        setUploadMsg(`${file.name} was not uploaded. Wrong file type.`)
        setOpen(true)
        return
      }
      const key = clientId + '/' + location.projectId + '/' + inspectionId + '/' + file.name
      try {
        await Storage.put(`upload/${key}`, file, {
          contentType: 'image/jpg',
        });

        const photo = await API.graphql(graphqlOperation(createPhoto, { input: { 
          groupId: location.groupId || location.projectId,
          file: { 
            bucket: bucket,
            key: key,
            region: region
          },
          inspectionId: inspectionId,
          projectId: location.projectId
        }})) as any
        const photoId = photo.data.createPhoto.id
        await API.graphql(graphqlOperation(createLocationPhoto, { input: {
          groupId: location.groupId || location.projectId,
          locationId: location.id,
          photoId: photoId,
          projectId: location.projectId
        }})) as any
        fetchLocation()
      } catch (error) {
        setUploadMsg(`error.response.data.message`)
        setOpen(true)
      }
    }
    Array.from(e.target.files).forEach(file => uploadPhoto(file, e.target.id))
    doneUploading()
  }

  const handleChange = (e: any) => {
    setProgress(true)
    onChange(e).then(() => {
      setProgress(false)
    })
  }

  const handleTagEdit = (tag: any) => {
    setDialogTitle('Edit Tag')
    setTagToEdit(tag)
    setDialogBtnTxt('Update')
    setDialogMsg('* This will update the tag text on all locations where this tag is used.')
    setDialogOpen(true)
  }

  const handleTagDelete = async (tag: any) => {
    warn(`Tag deleted on location ${location.id}`)
    await API.graphql(graphqlOperation(deleteLocationTag, { input: { id: tag.id, _version: tag._version } }))
    fetchLocationTags()
    doneUploading()
  }

  const handleDialogClose = (button: string) => {
    setDialogOpen(false)
    if (button === 'Update') {
      editTag(tagToEdit)
    } else if (button === 'Save') {
      addTag(addTagValue)
    } else if (button === 'Cancel') {
      setAddTagValue(null)
    }
  }

  const handleAddTag = () => {
    setDialogTitle('Add Tag')
    setTagToEdit({})
    setDialogMsg('')
    setDialogBtnTxt('Save')
    setDialogOpen(true)
  }

  const filter = createFilterOptions<{ inputValue?: string, extra: string }>();

  const handlePrimaryLocationChange = (e: any) => {
    changePrimaryLocation(e.target.id)
    doneUploading()
  }

  const sortCreateDate = (a: number, b: number) => {
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  }


  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    warn(`Photos reordered on location ${location.id}`)
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)
    location.photoSequence = list.map(p => p.id)
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorder(
      locationPhotos,
      result.source.index,
      result.destination.index
    );
    updateReordering(true)
  }

  const saveLocationPhotos = async() => {
    await API.graphql(graphqlOperation(updateLocation, { input: { id: location.id, _version: location._version, photoSequence: location.photoSequence } }))
    updateReordering(false)
    doneUploading(true)
  }
  
  const changePhotoReportable = async (item: UpdatePhotoInput) => {
    const rawItem: any = { ...item }
    await API.graphql(graphqlOperation(updatePhoto, {
      input: {
        id: rawItem.photo.id,
        _version: rawItem.photo._version,
        reportable: rawItem.photo.reportable === null ? !!rawItem.photo.reportable : !rawItem.photo.reportable
      } as UpdatePhotoInput
    }))
    //doneUploading(true)
    fetchLocation()
  }

  if (!location) return <></>

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" flexDirection="row">
          {location.primaryPhoto && <PhotoItem
            id={location.primaryPhoto.id}
            annotatorColor={annotatorColor}
            editable={editable}
            fetchLocation={fetchLocation}
            doneUploading={doneUploading}
            warn={warn} 
            excluded={true} />
          }
          <Box style={{ marginLeft: 10 }}>
            <Grid container item xs={12} alignItems="center">
              <Typography variant="h6">
                Location
              </Typography>
              <FormControlLabel
                style={{ marginLeft: 40 }}
                control={<Checkbox id={id} disabled={!canUpload} checked={primaryLocation} color="primary" onChange={handlePrimaryLocationChange} name="primary" />}
                label="Primary"
              />
            </Grid>
            {locationTags.sort((a, b) => { return sortCreateDate(a.createdAt, b.createdAt) }).map(t =>
              <Chip
                icon={<EditIcon style={{ display: canUpload ? "inline" : "none" }} />}
                onClick={() => handleTagEdit(t.tag)}
                key={t.id}
                disabled={!canUpload}
                label={t.tag.text}
                style={{ marginRight: 10 }}
                onDelete={() => handleTagDelete(t)}
                deleteIcon={<DeleteIcon style={{ display: canUpload ? "inline" : "none" }} />}
              />
            )}
            {canUpload && (locationTags.length < 5) &&
              <Fab
                size="small"
                onClick={handleAddTag}
                color="primary"
                aria-label="add"
              >
                <AddIcon />
              </Fab>
            }
          </Box>
        </Box>
        {locationPhotos?.length > 0 &&
          <>
            <Divider />
            <Box display="flex" flexDirection="row">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!canUpload} >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {locationPhotos?.filter(p => p.photoId !== location.primaryPhoto?.id).map((item, index) => {
                      return  <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                item
                              )}
                            >
                              <LocationPhotoItem
                                key={item.id}
                                item={item}
                                annotatorColor={annotatorColor}
                                onSetPrimary={setPrimaryPhoto}
                                onDelete={deletePhoto}
                                editable={editable && canUpload && !reordering}
                                fetchLocation={fetchLocation}
                                doneUploading={doneUploading}
                                warn={warn}
                                onChangeReportable={changePhotoReportable}
                                reportable={({ ...item } as any).photo.reportable}
                              />
                            </div>
                          )}
                        </Draggable>
                      }
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </>
        }
        <Grid container >
          <Grid item xs={3}>
            {!reordering ?
              <>
                {canUpload &&
                  <Box alignItems="center">
                    <label htmlFor={"upload" + location.id}>
                      <input
                        disabled={!canUpload}
                        style={{ display: 'none' }}
                        id={"upload" + location.id}
                        name="upload-photo"
                        type="file"
                        multiple
                        onChange={(e) => handleChange(e)}
                      />
                      <Button
                        style={{ marginTop: 5 }}
                        disabled={!canUpload}
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        Upload Photos
                      </Button>
                    </label>
                    <Button 
                      style={{ marginTop: 5, marginLeft: 10 }}
                      color="primary"
                      variant="contained"
                      onClick={() => doneUploading()}
                    >
                      Done Editing
                    </Button>
                  </Box>
                }
              </> :
              <LoadingButton
                style={{ marginTop: 5 }}
                variant="contained"
                color="primary"
                onClick={() => saveLocationPhotos()}
                disabled={!reordering}
              >
                Save New Order
                </LoadingButton>
            }

          </Grid>
          <Grid item xs={2}>
            {progress &&
              <CircularProgress />
            }
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={uploadMsg}
      />
      <Dialog open={dialogOpen} >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMsg}
          </DialogContentText>
          {dialogBtnTxt === 'Update' ?
            <TextField
              defaultValue={tagToEdit.text}
              onChange={(e) => setTagToEdit({ ...tagToEdit, text: e.target.value })}
            >
            </TextField>
            :
            <Autocomplete
              value={addTagValue ? addTagValue : ''}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setAddTagValue(
                    newValue,
                  );
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setAddTagValue(newValue.inputValue);
                } else {
                  setAddTagValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      extra: `Add "${params.inputValue}"`,
                    });
                  }
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              // options={locationTags.map(t => t.tag.text)}
              options={projectTags ? projectTags.filter((t: any) => !locationTags.map(t => t.tag.text).includes(t.text)) : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.extra;
                }
                // Regular option
                return option.text;
              }}
              style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Select Tag" variant="outlined" />
              )}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose('Cancel')} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(dialogBtnTxt)} color="primary">
            {dialogBtnTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

