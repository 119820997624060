import React, { useCallback, useEffect, useState } from 'react';
import { Box, Dialog } from '@material-ui/core';
import { UpdatePhotoInput } from '../API';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { getPhoto } from '../graphql/queries';
import Logo from '../assets/criterium-triangle.svg';
import Excluded from '../assets/excluded.svg';
import { PhotoAnnotator } from './PhotoAnnotator';
import { updatePhoto } from '../graphql/mutations';

type Props = {
  id: string,
  annotatorColor: string,
  editable?: boolean,
  width?: number,
  height?: number,
  fetchLocation?: any,
  doneUploading?: any,
  warn?: Function,
  excluded?: boolean
};

export const PhotoItem: React.FC<Props> = (props) => {
  const { id, annotatorColor, editable = false, width = 100, height = 100, fetchLocation, doneUploading } = props
  const [annotating, setAnnotating] = useState<boolean>(false)
  const [photo, setPhoto] = useState<UpdatePhotoInput>()
  const [photoUrl, setPhotoUrl] = useState<string>()
  const fetchPhoto = useCallback(async () => { 
    const photoData = await API.graphql(graphqlOperation(getPhoto, { id: id })) as any
    const photo = photoData.data.getPhoto as any
    setPhoto(photo)

    if (photo.file?.key) {
      // Use unscaled image for web app. Also helps with not
      // seeing a blank image while lambda re-scales image.
      const keyForUnscaledImage = 'upload/' + photo.file.key
      const photoUrl = await Storage.get(keyForUnscaledImage) as string
      setPhotoUrl(photoUrl)
    }
  }, [id])

  useEffect(() => {
    fetchPhoto()
  }, [fetchPhoto])

  const save = async (json: any, description: string) => {
    if (!photo) return
    if (props.warn) {
      props.warn(`Photo text or annotation changed on photo ${photo.id}`)
    }
    let jsonString;
    if (!json.objects || json.objects.length === 0) {
      jsonString = null
    } else {
      jsonString = JSON.stringify(json)
    }

    const photoData = await API.graphql(graphqlOperation(updatePhoto, { input: {
      id: photo.id,
      _version: photo._version,
      json: jsonString,
      text: description
    }})) as any
    const updatedPhoto = photoData.data.updatePhoto as any
    setPhoto(updatedPhoto)
    setAnnotating(false)
    fetchLocation && fetchLocation()
    doneUploading && doneUploading()
  }
  return (
    <>
      <Box onClick={() => setAnnotating(true)} key={id} style={{ cursor: 'pointer', position: 'relative', background: '#cccccc', margin: 5, width: width, height: height }}>
        { photo?.json?.length && 
          <img alt="" style={{ position: 'absolute', bottom: 5, right: 5 }} src={Logo} height={40}/>
        }
        { photoUrl &&
          <img alt="" style={{ width: '100%', height: '100%' }} src={photoUrl}/>
        }
        { !(props.excluded === null || props.excluded) &&
          <img alt="" style={{ position: 'absolute', bottom: 10, left: 0 }} src={Excluded}/>
        }
      </Box>
      { photo && photoUrl && 
        <Dialog fullScreen open={annotating}>
            <PhotoAnnotator 
              photo={photo} 
              url={photoUrl} 
              onSave={save} 
              onCancel={() => setAnnotating(false)} 
              editable={editable}
              annotationColor={annotatorColor}
            />
        </Dialog>
      }
    </>
  );
}