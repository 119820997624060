import React, {  useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Grid, TextField } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { getProject } from '../graphql/queries';
import { UpdateProjectInput } from '../API';
import { updateProject } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';

export default function ProjectNotes() {
  const { id } = useParams() as any

  const [project, setProject] = useState<UpdateProjectInput>()
  const [changes, setChanges] = useState<any>(undefined)

  const fetchProject = useCallback(async () => { 
    const projectData = await API.graphql(graphqlOperation(getProject, { id: id })) as any
    setProject(projectData.data.getProject)
  }, [id])

  const setProjectValue = (event: any) => {
    setProject({
      ...project,
      [event.target.name]: event.target.value
    } as UpdateProjectInput)

    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })
  }

  const saveChanges = async () => {
    const projectData = await API.graphql(graphqlOperation(updateProject, { 
      input: {
        id: project!.id,
        _version: project!._version,
        ...changes
      }
    })) as any

    setProject(projectData.data.updateProject)
    setChanges(undefined)
  }

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  if (!project) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12}>
          <TextField
            name="notes"
            label="Notes"
            fullWidth
            variant="outlined"
            multiline
            rows={20}
            rowsMax={20}
            value={project.notes || ''}
            onChange={setProjectValue}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton 
            variant="contained"
            color="primary" 
            onClick={saveChanges} 
            disabled={!changes}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}