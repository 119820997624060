import { Grid, Typography } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { UpdateProjectInput, UpdateUserInput } from "../API";
import TransferList from "../common/TransferList";
import { updateProject } from "../graphql/mutations";
import {listUsers, projectContactsByProjectId} from '../graphql/queries'

interface ProjectDistributionListProps  {
    project: UpdateProjectInput
    fetchProject: Function
}

const ProjectDistributionList = ({project, fetchProject}: ProjectDistributionListProps) => {

const [distributionList, setDistributionList] = useState<any>(project.distributionList ? JSON.parse(project!.distributionList)  : {"to": [], "cc": [], "bcc": []})
const [contacts, setContacts] = useState<UpdateUserInput[]>()

const fetchContacts = useCallback(async () => {
    const contactData = await API.graphql(graphqlOperation(projectContactsByProjectId, { projectId: project.id })) as any
    const teamData = await API.graphql(graphqlOperation(listUsers, { filter: {status: {eq: "ACTIVE"}} })) as any
    const contacts = contactData.data.projectContactsByProjectId.items
    const team = teamData.data.listUsers.items.filter((r: any) => r._deleted !== true)
    setContacts(contacts.map((contact: any) => contact.contact).concat(team))
}, [project])

useEffect(() => {
    fetchContacts()
}, [fetchContacts])

const updateDistributionList = async(list: [any], listType: string ) => {
    setDistributionList({...distributionList, [listType]: list})
    await API.graphql(graphqlOperation(updateProject, { 
        input: {
            id: project.id, 
            _version: project._version, 
            distributionList: JSON.stringify({...distributionList, [listType]: list})  
        }
    })) as any
    fetchProject()
}

if (!contacts) return (<>Loading.....</>)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold" }}>TO:</Typography>
            </Grid>
            <Grid item xs={12}>
                <TransferList  field="to" updateDistributionList={updateDistributionList} items={distributionList.to} contacts={contacts} />
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold" }}>CC:</Typography>
            </Grid>
            <Grid item xs={12}>
                <TransferList field="cc" updateDistributionList={updateDistributionList} items={distributionList.cc} contacts={contacts} />
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold" }}>BCC:</Typography>
            </Grid>
            <Grid item xs={12}>
                <TransferList field="bcc" updateDistributionList={updateDistributionList} items={distributionList.bcc} contacts={contacts} />
            </Grid>
        </Grid>
    )
}

export default ProjectDistributionList