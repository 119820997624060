import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, Menu, MenuItem, Switch } from '@material-ui/core';
import { UpdateLocationPhotoInput } from '../API';
import { PhotoItem } from './PhotoItem';

type Props = {
  item: UpdateLocationPhotoInput,
  annotatorColor: string,
  editable?: boolean,
  onSetPrimary: Function,
  onDelete: Function,
  fetchLocation: Function,
  doneUploading: Function,
  warn: Function,
  onChangeReportable: Function,
  reportable?: boolean
};

export const LocationPhotoItem: React.FC<Props> = (props) => {
  const { item, annotatorColor, editable, onSetPrimary, onDelete, fetchLocation, doneUploading, warn, onChangeReportable } = props
  const rawItem = item as any

  const [deleteConfirmation, setDeleteConfirmation] = React.useState<boolean>(false);
  const confirmDelete = () => {
    closeMenu()
    setDeleteConfirmation(true)
  }
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {   
    event.preventDefault();

    if (editable) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const setPrimary = () => {
    onSetPrimary(item)
  }

  const _onDelete = () => {
    setDeleteConfirmation(false)
    onDelete(item)
  }

  const changeReportable = async () => {
    onChangeReportable(item)
    closeMenu()
  }
  return (
    <div onContextMenu={openMenu}>
      <PhotoItem
        id={item.photoId!}
        annotatorColor={annotatorColor}
        editable={editable}
        fetchLocation={fetchLocation}
        doneUploading={doneUploading}
        warn={warn}
        excluded={props.reportable}
      />
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
      >
        <MenuItem onClick={setPrimary}>Set as Primary</MenuItem>
        <MenuItem onClick={confirmDelete}>Delete</MenuItem>
        <MenuItem>
          <FormGroup row >
            <FormControlLabel
              control={
                <Switch
                  checked={rawItem.photo.reportable === null ? true : rawItem.photo.reportable}
                  onChange={changeReportable}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
              label="Show on Report"
            />
          </FormGroup>
        </MenuItem>
      </Menu>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={deleteConfirmation}
      >
        <DialogTitle style={{ width: 400 }}>Delete Photo?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={_onDelete} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}