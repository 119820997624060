import { Box, Button, ButtonGroup } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FindingType, ObservationType, UpdateFindingInput, UpdateObservationInput } from '../API';
import LoadingIndicator from '../common/LoadingIndicator';
import { updateObservation } from '../graphql/mutations';
import { getFinding, getObservation } from '../graphql/queries';
import { InspectionContext } from './InspectionContext';
import { ObservationContext, ObservationContextType } from './ObservationContext';
import { ObservationDetail } from './ObservationDetail';

type Props = {
  editable?: boolean
};

export const ObservationView: React.FC<Props> = (props) => {
  const { oId } = useParams() as any

  const { inspection, editable } = useContext(InspectionContext)

  const [observation, setObservation] = useState<UpdateObservationInput>()
  const fetchObservation = useCallback(async () => {
    const observationData = await API.graphql(graphqlOperation(getObservation, { id: oId })) as any
    const observation = observationData.data.getObservation
    setObservation(observation)
  }, [oId])

  useEffect(() => {
    fetchObservation()
  }, [fetchObservation])

  const [observationContext, setObservationContext] = useState<ObservationContextType>()
  useEffect(() => {
    const update = async (updates: any) => {
      let input: UpdateObservationInput = {
        id: observation!.id,
        _version: observation!._version,
        ...updates
      }

      const observationData = await API.graphql(graphqlOperation(updateObservation, { input: input })) as any
      const updatedObservation = observationData.data.updateObservation
      setObservation(updatedObservation)

    }

    if (observation) {
      setObservationContext({
        inspection: inspection,
        editable: editable,
        observation: observation,
        update: update
      })
    } else {
      setObservationContext({
        inspection: inspection,
        editable: editable,
        observation: undefined,
        update: update
      })
    }
  }, [observation, inspection, editable])

  const [finding, setFinding] = useState<UpdateFindingInput>()
  const fetchFinding = useCallback(async () => {
    if (observation?.findingId) {
      const findingData = await API.graphql(graphqlOperation(getFinding, { id: observation.findingId })) as any
      const finding = findingData.data.getFinding
      setFinding(finding)
    } else {
      setFinding(undefined)
    }
  }, [observation])

  useEffect(() => {
    fetchFinding()
  }, [fetchFinding])

  const [originalContext, setOriginalContext] = useState<ObservationContextType>()
  const fetchOriginalObservation = useCallback(async () => {
    const update = async (updates: any) => { }
    if (finding?.observationId) {
      const observationData = await API.graphql(graphqlOperation(getObservation, { id: finding.observationId })) as any
      const observation = observationData.data.getObservation
      setOriginalContext({
        inspection: inspection,
        editable: false,
        observation: observation,
        update: update
      })
    } else {
      setOriginalContext({
        inspection: inspection,
        editable: false,
        update: update
      })
    }
  }, [finding, inspection])

  useEffect(() => {
    fetchOriginalObservation()
  }, [fetchOriginalObservation])

  const [activeObservation, setActiveObservation] = useState<'opening' | 'closing'>('closing')

  if (!observation || !observationContext) {
    return <LoadingIndicator />
  }

  if (observation.type !== ObservationType.RESOLUTION) {
    return (
      <ObservationContext.Provider value={observationContext}>
        <ObservationDetail finding={finding!} />
      </ObservationContext.Provider>
    )
  } else if (!finding || !originalContext) {
    return <LoadingIndicator />
  }

  return (
    <Box>
      <ButtonGroup size="medium" fullWidth style={{ marginBottom: 20 }}>
        <Button
          variant={activeObservation === 'closing' ? 'contained' : 'outlined'}
          onClick={() => setActiveObservation('closing')}
          color="primary" style={{ flex: 1 }}
        >
          {finding?.type === FindingType.NCI ? 'Correction' : 'Resolution'}
        </Button>
        <Button
          variant={activeObservation === 'opening' ? 'contained' : 'outlined'}
          onClick={() => setActiveObservation('opening')}
          color="primary"
          style={{ flex: 1 }}
        >
          Issue
        </Button>
      </ButtonGroup>

      {activeObservation === 'closing' &&
        <ObservationContext.Provider value={observationContext}>
          <ObservationDetail finding={finding} />
        </ObservationContext.Provider>
      }
      {activeObservation === 'opening' &&
        <ObservationContext.Provider value={originalContext}>
          <ObservationDetail finding={finding} />
        </ObservationContext.Provider>
      }
    </Box>
  )
}
