import React, { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Grid, Typography } from '@material-ui/core';

import { ContactEditor } from './ContactEditor';
import { useParams, useNavigate } from 'react-router-dom';
import { getContact } from '../graphql/queries';
import { updateContact } from '../graphql/mutations';
import { UpdateContactInput } from '../API';
import LoadingIndicator from '../common/LoadingIndicator';

export const ContactView = () => {
  const { id } = useParams() as any;
  const navigate = useNavigate();
 
  const [contact, setContact] = useState<UpdateContactInput>()

  const fetchContact = useCallback(async () => { 
    const contactData = await API.graphql(graphqlOperation(getContact, { id: id })) as any
    setContact(contactData.data.getContact)
  }, [id])

  useEffect(() => {
    fetchContact()
  }, [fetchContact])

  async function onSave(changes: any) {
    try {
      await API.graphql(graphqlOperation(updateContact, {input: {
        id: contact!.id,
        _version: contact!._version,
        ...changes
      }}))
    } catch (err) {
      console.log('error saving contact:', err)
    }
  }

  function onCancel() {
    navigate('/contacts')
  }

  if (!contact) {
    return <LoadingIndicator/>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Contact
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <ContactEditor 
          contact={contact} 
          editing={true} 
          onSave={onSave} 
          onCancel={onCancel}/>
      </Grid>
    </Grid>
  );
}

export default ContactView
