import React, { useEffect, useRef, useState } from 'react';
import { UpdatePhotoInput } from '../API';
import { Annotator } from 'fabricjs-annotator';
import { Box, Button, TextField } from '@material-ui/core';
import { CallMade, CheckBoxOutlineBlank, Create, Delete, RadioButtonUnchecked } from '@material-ui/icons';

type Props = {
  photo: UpdatePhotoInput,
  url: string,
  editable?: boolean
  onSave: Function,
  onCancel: Function,
  annotationColor: string
};

export const PhotoAnnotator: React.FC<Props> = (props) => {
  const { photo, url, onSave, onCancel, annotationColor, editable = false } = props

  const annotator = useRef<Annotator>()
  const [activeTool, setActiveTool] = useState<string>()
  const [canDelete, setCanDelete] = useState<boolean>(false)
  const [description, setDescription] = useState<string | null>(photo.text || null)

  useEffect(() => {
    annotator.current = new Annotator('fabricJsAnnotator', false)
    annotator.current.init({
      imageUrl: url,
      readonly: !editable,
      json: photo.json
    })

    function handleToolCleared() {
      setActiveTool(undefined)
    }

    function handleSelectionCreated() {
      setCanDelete(true)
    }

    function handleSelectionCleared() {
      setCanDelete(false)
    }

    annotator.current.on('tool:cleared', handleToolCleared)
    annotator.current.on('selection:cleared', handleSelectionCleared)
    annotator.current.on('selection:created', handleSelectionCreated)

    return function cleanup() {
      annotator.current?.off('tool:cleared', handleToolCleared)
      annotator.current?.off('selection:cleared', handleSelectionCleared)
      annotator.current?.off('selection:created', handleSelectionCreated)
    }
  }, [url, editable, photo])

  useEffect(() => {
    let timeout: any = null;
    const resizeListener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        annotator.current?.resizeCanvas()
      }, 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const draw = () => {
    if (activeTool !== 'draw') {
      annotator.current?.draw({ color: annotationColor, width: 10 })
      setActiveTool("draw")
    } else {
      annotator.current?.clearTool()
      setActiveTool(undefined)
    }
  }

  const circle = () => {
    if (activeTool !== 'circle') {
      annotator.current?.circle({ color: annotationColor, width: 10 })
      setActiveTool("circle")
    } else {
      annotator.current?.clearTool()
      setActiveTool(undefined)
    }
  }

  const rectangle = () => {
    if (activeTool !== 'rectangle') {
      annotator.current?.rectangle({ color: annotationColor, width: 10 })
      setActiveTool("rectangle")
    } else {
      annotator.current?.clearTool()
      setActiveTool(undefined)
    }
  }

  const arrow = () => {
    if (activeTool !== 'arrow') {
      annotator.current?.arrow({ color: annotationColor, width: 10 })
      setActiveTool("arrow")
    } else {
      annotator.current?.clearTool()
      setActiveTool(undefined)
    }
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor='#000000' justifyContent="space-evenly">
      <Box style={{ position: 'relative', zIndex: 1000 }}>
        { editable && 
          <Button 
            variant="contained" 
            style={{ backgroundColor: '#000000', opacity: .7, color: '#ffffff', borderRadius: 25, fontWeight: 'bold', position: 'absolute', width: 100, height: 50, top: 20, left: 20 }}
            onClick={() => onSave(annotator.current?.exportJson(), description)}
          >
            Save
          </Button>
        }
        <Button 
          variant="contained" 
          style={{ backgroundColor: '#000000', opacity: .7, color: '#ffffff', borderRadius: 25, fontWeight: 'bold', position: 'absolute', width: 100, height: 50, top: 20, left: editable ? 140 : 20 }}
          onClick={() => onCancel()}
        >
          { editable ? 'Cancel' : 'Close' }
        </Button>
        { editable &&
          <Box style={{ position: 'absolute', right: 10, top: 30, width: 65 }}>
            <Box style={{ backgroundColor: '#000000', paddingTop: 40, paddingBottom: 40, borderRadius: 40, opacity: .7 }}> 
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => draw()}
              >
                <Create fontSize="large" htmlColor={ activeTool === 'draw' ? '#233988' : '#ffffff'} />
              </Button>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => circle()}
              >
                <RadioButtonUnchecked fontSize="large" htmlColor={ activeTool === 'circle' ? '#233988' : '#ffffff'} />
              </Button>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => rectangle()}
              >
                <CheckBoxOutlineBlank fontSize="large" htmlColor={ activeTool === 'rectangle' ? '#233988' : '#ffffff'} />
              </Button>
              <Button
                onClick={() => arrow()}
              >
                <CallMade fontSize="large" htmlColor={ activeTool === 'arrow' ? '#233988' : '#ffffff'} />
              </Button>
            </Box>
            { canDelete &&
              <Box style={{ backgroundColor: '#000000', marginTop: 20, paddingTop: 20, paddingBottom: 20, borderRadius: 40, opacity: .7 }}>
                <Button
                  onClick={() => annotator.current?.deleteSelection()}
                >
                  <Delete fontSize="large" htmlColor="#ffffff" />
                </Button>
              </Box>
            }
          </Box>
        }
      </Box>
      <Box display="flex" justifyContent="center" mt="50px" mb="10px">
        <canvas id="fabricJsAnnotator"/>
      </Box>
      { (editable || photo.text) && 
        <Box display="flex" bgcolor='#ffffff' p="10px">
          <TextField
            multiline
            fullWidth
            rows={3}
            label="Description"
            value={description || ''}
            variant="outlined"
            onChange={(event: any) => setDescription(event.target.value)}
            InputProps={{
              readOnly: !editable,
            }}
          />
        </Box>
      }
    </Box>
  )
}