import React, { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createInspectionPoint, updateInspectionPoint } from '../graphql/mutations';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import { CreateInspectionPointInput, InspectionPointType, UpdateInspectionPointInput } from '../API';
import { inspectionPointsByHierarchyItemId, observationsByInspectionPointId } from '../graphql/queries';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

export const HierarchyItemInspectionPointView: React.FC<{hierarchyItemId: string}> = ({hierarchyItemId}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [inspectionPoint, setInspectionPoint] = useState<CreateInspectionPointInput | UpdateInspectionPointInput>()
  const [inspectionPointChanges, setInspectionPointChanges] = useState<any>({})

  const [inspectionPoints, setInspectionPoints] = useState<UpdateInspectionPointInput[]>([])
  const fetchInspectionPoints = useCallback(async () => { 
    try {
      const inspectionPointData = await API.graphql(graphqlOperation(inspectionPointsByHierarchyItemId, {
        hierarchyItemId: hierarchyItemId
      })) as any
      const inspectionPoints = inspectionPointData.data.inspectionPointsByHierarchyItemId.items
        .filter((i: any) => !i._deleted)
        .sort((a: any, b: any) => a.text > b.text ? 1 : -1)

      setInspectionPoints(inspectionPoints)
    } catch (err) { console.log('error fetching inspection points') }
  }, [hierarchyItemId])
  
  useEffect(() => {
    fetchInspectionPoints()
  }, [fetchInspectionPoints])

  const [inUse, setInUse] = useState<boolean>(false)
  const showInspectionPointDialog = async (inspectionPoint?: UpdateInspectionPointInput) => {
    if (inspectionPoint) {
      setInspectionPoint(inspectionPoint) 
      const inUseData = await API.graphql(graphqlOperation(observationsByInspectionPointId, {
        inspectionPointId: inspectionPoint.id,
        limit: 1
      })) as any
      setInUse(inUseData.data.observationsByInspectionPointId.items.length > 0)
    } else {
      setInspectionPoint({
        text: '',
        code: ''
      } as CreateInspectionPointInput)
      setInUse(false)
    }
    setDialogOpen(true)
  }

  const hideInspectionPointDialog = () => {
    setInspectionPoint(undefined)
    setInUse(false)
    setDialogOpen(false)
  }

  function setInspectionPointValue(key: string, value: string) {
    setInspectionPointChanges({
      ...inspectionPointChanges,
      [key]: value
    })

    setInspectionPoint({ 
      ...inspectionPoint, 
      [key]: value
    } as CreateInspectionPointInput | UpdateInspectionPointInput)
  }

  async function addInspectionPoint() {
    if (!inspectionPoint || !inspectionPoint.text) return

    let ip: UpdateInspectionPointInput;
    try {
      if (!inspectionPoint.id) {
        const hierarchyItemData = await API.graphql(graphqlOperation(createInspectionPoint, {input: {
          ...inspectionPointChanges,
          type: InspectionPointType.APPROVED,
          hierarchyItemId: hierarchyItemId
        } as CreateInspectionPointInput})) as any
        ip = hierarchyItemData.data.createInspectionPoint

        setInspectionPoints([
          ...inspectionPoints,
          ip
        ])
      } else {
        const hierarchyItemData = await API.graphql(graphqlOperation(updateInspectionPoint, {input: {
          id: inspectionPoint.id,
          _version: inspectionPoint._version,
          ...inspectionPointChanges
        } as UpdateInspectionPointInput})) as any
        ip = hierarchyItemData.data.updateInspectionPoint

        let newInspectionPoints = [ ...inspectionPoints]
        let idx = newInspectionPoints.findIndex(i => i.id === ip.id)
        newInspectionPoints[idx] = ip

        setInspectionPoints(newInspectionPoints)
      }  

      setInspectionPoint(undefined)
      setDialogOpen(false)
    } catch (err) {
      console.log('error saving inspection point:', err)
    }
  }

  return (
    <Grid container spacing={3} style={{ marginTop: 20 }}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Inspection Points
        </Typography>
      </Grid>
      {inspectionPoints.map(inspectionPoint => (
        <Grid item xs={12} key={inspectionPoint.id} onClick={() => showInspectionPointDialog(inspectionPoint)}>
          <div style={{ cursor: 'pointer'}}>
            <span>{inspectionPoint.code}</span>
            <span style={{ 
              textDecoration: inspectionPoint.hidden? 'line-through' : 'auto',
              opacity: inspectionPoint.hidden? '.5' : 'unset'
            }}>{inspectionPoint.text}</span>
          </div>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => showInspectionPointDialog()}
        >
          Add Inspection Point
        </Button>
        <Dialog fullWidth={true} maxWidth="sm" open={dialogOpen} onClose={hideInspectionPointDialog}>
          {inspectionPoint?.id && <DialogTitle>Update Inspection Point</DialogTitle> }
          {!inspectionPoint?.id && <DialogTitle>Add Inspection Point</DialogTitle> }
          <DialogContent>
            {inUse &&
              <Alert style={{ marginBottom: 20 }} severity="warning">
                This inspection point has been used. Updates will also update historical usages
              </Alert>
            }
            <TextField 
              style={{ marginBottom: 10 }}
              label="Inspection Point Text" 
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event: any) => setInspectionPointValue("text", event.target.value)}
              value={inspectionPoint?.text}
            />
            <TextField 
              label="Inspection Point Code" 
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event: any) => setInspectionPointValue("code", event.target.value)}
              value={inspectionPoint?.code}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={inspectionPoint?.hidden || false}
                  onChange={(event: any) => setInspectionPointValue("hidden", event.target.checked)}
                  color="primary"
                />
              }
              label="Hidden"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={hideInspectionPointDialog} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={addInspectionPoint} color="primary">
              {!inspectionPoint?.id && <>Add</> }
              {inspectionPoint?.id && <>Update</> }
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}