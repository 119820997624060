import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Paper, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

type Props = {
    activityStreamItems : any[]
};

export const ActivityStream: React.FC<Props> = (props) => {
    return (
        <Timeline align="alternate">
        { props.activityStreamItems.map((a: any) =>
              <TimelineItem key={a.id}>
              <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary">
                  { new Date(a.date).toLocaleString() }
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  { a.user.firstName + ' ' + a.user.lastName}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <InfoIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} style={{ backgroundColor: 'silver'}}>
                  <Typography style={{ padding: 6 }} variant='body1'>{a.text}</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
        )}
      </Timeline>
    )
}