import { Box, Button, TextField } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { CreateExternalLinkInput, UpdateExternalLinkInput } from "../API";

export const ExternalLinkEditor: React.FC<{item: UpdateExternalLinkInput | CreateExternalLinkInput, onSave: Function, onCancel: Function}> = ({item, onSave, onCancel}) => {
    const [link, setLink] = useState<UpdateExternalLinkInput | CreateExternalLinkInput>(item)
    const [changes, setChanges] = useState<any>({})

    function setExternalLinkValue(event: ChangeEvent<HTMLInputElement>) {
        setLink({
            ...link,
            [event.target.name]: event.target.value
        })

        setChanges({
            ...changes,
            [event.target.name]: event.target.value
        })
    }

    function cancel() {
        setLink(item)
        setChanges({})
        onCancel()
    }

    function save() {
        setLink(link)
        onSave(changes)
        setChanges({})
    }

    return (
        <Box display="flex" flexDirection="column">
            <TextField 
                style={{marginBottom: 10}}
                label="Title" 
                variant="outlined"
                size="small"
                fullWidth
                name="title"
                onChange={setExternalLinkValue}
                value={link.title || ''}
            />
            <TextField 
                style={{marginBottom: 10}}
                label="URL" 
                variant="outlined"
                size="small"
                fullWidth
                name="url"
                onChange={setExternalLinkValue}
                value={link.url || ''}
            />
            <Box display="flex" flexDirection="row">
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={cancel}
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={!(changes && link.url && link.title)}
                    onClick={save}
                    style={{ fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 }}
                >
                    Save
                </Button>
            </Box>
        </Box>
    )
}