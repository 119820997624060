import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MilestoneTrackerInput, UpdateContactInput, UpdateInspectionPhotoInput, UpdatePhotoInput } from '../API';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { photosByInspectionId, observationsByInspectionId, getFinding } from '../graphql/queries';
import { AccountCircleOutlined, Edit } from '@material-ui/icons';
import {
  deleteInspectionPhoto,
  deletePhoto,
  createPhoto,
  createInspectionPhoto,
  updateInspection,
  updateFinding,
  updatePhoto
} from '../graphql/mutations';
import { parse } from 'date-fns';
import { ContactPicker } from '../contact/ContactPicker';
import { InspectionPhotoItem } from './InspectionPhotoItem';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import { InspectionContext } from './InspectionContext';
import { MilestoneGrid } from './MilestoneGrid';
import awsconfig from '../aws-exports';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type Props = {
};

const allDirections = ['NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N', ' ']

let weatherDescriptors = [
  'thunderstorm',
  'drizzle',
  'light rain',
  'moderate rain',
  'very heavy rain',
  'freezing rain',
  'light snow',
  'Snow',
  'Heavy snow',
  'Sleet',
  'mist',
  'Haze',
  'fog',
  'clear sky',
  'few clouds: 11-25%',
  'scattered clouds: 25-50%',
  'broken clouds: 51-84%',
  'overcast clouds: 85-100%',
  ' '
]

const degToCard = (value: number) => {
  if (value <= 11.25) return 'N';
  value -= 11.25;
  const dIndex = parseInt((value / 22.5).toString())
  return allDirections[dIndex] ? allDirections[dIndex] : 'N';
}

const cardToDeg = (point: string) => {
  return (allDirections.indexOf(point) + 1) * 22.5;
}


const grid = 1;

const getItemStyle = (isDragging: boolean, draggableStyle: any, item: any) => ({
  userSelect: 'none',
  padding: grid,
  margin: `0 ${grid}px 0 0`,

  // change background color if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

export const InspectionDetails: React.FC<Props> = (props) => {
  const { inspection, editable, update } = useContext(InspectionContext);
  const [inspectionId, setInspectionId] = useState<string>()
  const [changes, setChanges] = useState<any>()
  const [reordered, setReordered] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [inspectionDate, setInspectionDate] = useState<Date | null>()
  const [previousInspDate, setPreviousInspDate] = useState<Date | null>()
  const [arrivalTime, setArrivalTime] = useState<Date | null>()
  const [nextInspectionDate, setNextInspectionDate] = useState<Date | null>()
  const [checkInContact, setCheckInContact] = useState<string>('')
  const [inspectionPhotos, setInspectionPhotos] = useState<any[]>([])
  const [weather, setWeather] = useState<any>()
  const [open, setOpen] = useState(false)
  const [apiMsg, setApiMsg] = useState('')
  const [isMilestone, setIsMilestone] = useState(false)
  const [milestoneTrackers, setMilestoneTrackers] = useState<MilestoneTrackerInput[]>([])
  const [errors, setErrors] = useState<any>({
    temp: false,
    humidity: false,
    windSpeed: false
  })
  const [messages, setMessages] = useState<any>({
    temp: '',
    humidity: '',
    windSpeed: ''
  })

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)
    inspection!.photoSequence = list.map(p => p.id)
  };

  // Add current description to descriptors
  if (inspection!.weather) {
    const parsedWeather = JSON.parse(inspection!.weather)
    if (parsedWeather.weather) {
      if (!weatherDescriptors.includes(parsedWeather.weather[0].description)) {
        weatherDescriptors.push(JSON.parse(inspection!.weather).weather[0].description)
      }
    }
  }

  useEffect(() => {
    setInspectionId(inspection?.id);

    let rawInspection = inspection as any
    if (rawInspection?.project?.milestoneTemplateId || rawInspection?.phase?.milestoneTemplateId) {
      setIsMilestone(true)
    }
  }, [inspection])

  let rawInspection = inspection as any

  const handleWeatherChange = async () => {
    if (!inspectionDate || !arrivalTime) return
    const date = Math.floor(new Date(
      inspectionDate.toLocaleDateString()
      + ' ' +
      arrivalTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    ).getTime() / 1000)
    try {
      const weatherData = await API.get("weather", `/zip/${rawInspection?.project.address.zip}/${date}`, {})
      setChanges({
        ...changes,
        weather: JSON.stringify(weatherData)
      })
      if (!weatherDescriptors.includes(weatherData.weather[0].description)) {
        weatherDescriptors.push(weatherData.weather[0].description)
      }
      setWeather(weatherData)
      setApiMsg('This will overwrite current weather upon save.')
      setOpen(true)
    } catch (error: any) {
      setApiMsg(error.response.data.message)
      setOpen(true)
    }
  }

  const handleWeatherFieldChange = (event: React.ChangeEvent<any>) => {
    let newWeather;
    if (event.target.id === 'weather' || event.target.name === 'weather') {
      newWeather = { ...weather };
      newWeather.weather ? newWeather.weather[0].description = event.target.value : newWeather.weather = [{ description: event.target.value }];
    } else {
      if (event.target.id) {
        // validate.
        switch (event.target.id) {
          case 'temp':
            if (event.target.value.match(/^[-+]?[0-9]{0,3}$/)) {
              newWeather = { ...weather, [event.target.id]: event.target.value ? parseInt(event.target.value) : '' }
              if (event.target.value > -51 && event.target.value < 130) {
                setErrors({ ...errors, temp: false })
                setMessages({ ...messages, temp: "" })
              } else {
                setErrors({ ...errors, temp: true })
                setMessages({ ...messages, temp: "Too Extreme!" })
              }
            } else {
              return
            }
            break;
          case 'humidity':
            if (event.target.value.match(/^[0-9]{0,2}$/)) {
              setErrors({ ...errors, humidity: false })
              setMessages({ ...messages, humidity: "" })
              newWeather = { ...weather, [event.target.id]: event.target.value ? parseInt(event.target.value) : '' }
            } else {
              return
            }
            break;
          case 'wind_speed':
            if (event.target.value.match(/^[0-9]{0,2}$/)) {
              setErrors({ ...errors, windSpeed: false })
              setMessages({ ...messages, windSpeed: "" })
              newWeather = { ...weather, [event.target.id]: event.target.value ? parseInt(event.target.value) : '' }
            } else {
              return
            }
            break;
          default:
            return
        }
      } else if (event.target.name) {
        // Input is a select
        newWeather = { ...weather, [event.target.name]: cardToDeg(event.target.value) }
      }
    }
    // Delete all fields that were gotten with OWM
    // that aren't used.
    delete newWeather.sunset
    delete newWeather.dew_point
    delete newWeather.sunrise
    delete newWeather.visibility
    delete newWeather.uvi
    delete newWeather.pressure
    delete newWeather.clouds
    delete newWeather.feels_like
    delete newWeather.wind_gust
    delete newWeather.dt
    setChanges({
      ...changes,
      weather: JSON.stringify(newWeather)
    })
    setWeather(newWeather)
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchInspectionPhotos = useCallback(async () => {
    if (inspectionId) {
      const inspectionPhotosData = await API.graphql(graphqlOperation(photosByInspectionId, { inspectionId: inspectionId })) as any
      if (!inspection!.photoSequence) {
        inspection!.photoSequence = []
      }
      const inspectionPhotos = inspectionPhotosData.data.photosByInspectionId.items
        .filter((c: any) => !c._deleted) as any[]
      inspectionPhotos.sort((a: any, b: any) => 
      {
        const aIdx = inspection!.photoSequence!.indexOf(a.id)
        const bIdx = inspection!.photoSequence!.indexOf(b.id)
      
        if (aIdx < 0 && bIdx < 0) {
          if (a.createdAt < b.createdAt) {
            return -1
          } else if (a.createdAt > b.createdAt) {
            return 1
          } else {
            return 0
          }
        } else if (bIdx < 0) {
          return -1
        } else if (aIdx < 0) {
          return 1
        } else {
          return aIdx - bIdx
        }
      }
      )
      setInspectionPhotos(inspectionPhotos)
      // Now they are ordered, save sequence to inspection
      //const sequence = inspectionPhotos.map(p => p.id)
      // await API.graphql(graphqlOperation(updateInspection, { input: { id: inspection!.id, _version: inspection!._version, photoSequence: sequence } }))

    } else {
      setInspectionPhotos([])
    }
  }, [inspectionId, inspection])

  useEffect(() => {
    fetchInspectionPhotos()
  }, [fetchInspectionPhotos])

  const resetFieldNotes = useCallback(() => {
    if (!inspection) return

    if (inspection.notes) {
      setNotes(inspection.notes)
    }
    if (inspection.inspectionDate) {
      setInspectionDate(new Date(inspection.inspectionDate))
    }
    if (inspection.arrivalTime) {
      setArrivalTime(parse(inspection.arrivalTime, 'h:mm a', new Date()))
    }
    if (inspection.nextInspectionDate) {
      setNextInspectionDate(new Date(inspection.nextInspectionDate))
    }
    if (inspection.weather) {
      setWeather(JSON.parse(inspection.weather))
    }
    const rawInspection = inspection as any
    if (rawInspection.checkInContact) {
      setCheckInContact(`${rawInspection.checkInContact.firstName} ${rawInspection.checkInContact.lastName}`)
    } else {
      setCheckInContact('')
    }

    if (inspection.milestoneTrackers) {
      let _milestoneTrackers: MilestoneTrackerInput[] = inspection.milestoneTrackers.filter(t => t) as MilestoneTrackerInput[]
      setMilestoneTrackers(_milestoneTrackers)
    } else {
      setMilestoneTrackers([])
    }
  }, [inspection])

  useEffect(() => {
    resetFieldNotes()
  }, [inspection, resetFieldNotes])

  const handleInspectionDateChange = (date: Date | null) => {
    // date is not valid Date until complete
    try {
      setChanges({
        ...changes,
        inspectionDate: date?.toISOString()
      })
      if (!previousInspDate) {
        setPreviousInspDate(inspectionDate);
      }
      setInspectionDate(date);
    } catch {
      return
    }
  };

  const updateFindings = async () => {
    const observationData = await API.graphql(graphqlOperation(observationsByInspectionId, { inspectionId: inspection!.id })) as any
    const observations = observationData.data.observationsByInspectionId.items;
    for (let o of observations.filter((o: any) => o.type !== 'CONFORMING')) {
      const fData = await API.graphql(graphqlOperation(getFinding, { id: o.findingId })) as any
      const finding = fData.data.getFinding
      switch (o.type) {
        case 'FINDING':
          if (new Date(finding.date).getTime() === previousInspDate?.getTime()) {
            await API.graphql(graphqlOperation(updateFinding, { input: { id: o.findingId, _version: finding._version, date: inspectionDate?.toISOString() } })) as any
          }
          break
        case 'RESOLUTION':
          if (new Date(finding.closedDate).getTime() === previousInspDate?.getTime()) {
            await API.graphql(graphqlOperation(updateFinding, { input: { id: o.findingId, _version: finding._version, closedDate: inspectionDate?.toISOString() } })) as any
          }
          break
      }
    }

  }

  const handleArrivalTimeChange = (date: Date | null) => {
    setChanges({
      ...changes,
      arrivalTime: date?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    })
    setArrivalTime(date);
  };

  const handleNextInspectionDateChange = (date: Date | null) => {
    try {
      setChanges({
        ...changes,
        nextInspectionDate: date?.toISOString()
      })
      setNextInspectionDate(date);
    } catch {
      return
    }
  };

  const handleNotesChange = (Typography: string) => {
    setChanges({
      ...changes,
      notes: Typography
    })
    setNotes(Typography);
  };

  const [editingCheckInContact, setEditingCheckInContact] = useState<boolean>(false)
  const handleCheckInChange = (contact: UpdateContactInput) => {
    setChanges({
      ...changes,
      inspectionCheckInContactId: contact.id
    })
    setCheckInContact(`${contact.firstName} ${contact.lastName}`)
    setEditingCheckInContact(false)
  }

  const [editingFieldNotes, setEditingFieldNotes] = useState<boolean>(false)

  const cancelEditingFieldNotes = () => {
    resetFieldNotes()
    setChanges(undefined)
    setEditingFieldNotes(false)
  }

  const [saving, setSaving] = useState<boolean>(false)
  const saveFieldNotes = async () => {
    if (!inspection) return

    try {
      setSaving(true)
      await update(changes)
      // When inspection date is changed all findings
      // associated with that inspection will have their
      // date and closedDate changed to the new inspection date unless
      // there was an override.
      if (changes.inspectionDate) {
        updateFindings()
      }
      setEditingFieldNotes(false)
    } finally {
      setSaving(false)
      setChanges(undefined)
    }
  }

  const deletePhotoItem = async (item: UpdateInspectionPhotoInput) => {
    const deleteIdx = inspectionPhotos.findIndex(p => p.id === item.id)
    const rawItem: any = { ...item }
    await API.graphql(graphqlOperation(deletePhoto, { input: { id: item.photoId!, _version: rawItem.photo._version } }))
    await API.graphql(graphqlOperation(deleteInspectionPhoto, { input: { id: item.id, _version: item._version } }))

    const photos = [...inspectionPhotos]
    photos.splice(deleteIdx, 1)
    setInspectionPhotos([
      ...photos
    ])
    setEditingSitePhotos(false)
  }

  const changePhotoReportable = async (item: UpdatePhotoInput) => {
    const rawItem: any = { ...item }
    await API.graphql(graphqlOperation(updatePhoto, {
      input: {
        id: rawItem.photo.id,
        _version: rawItem.photo._version,
        reportable: rawItem.photo.reportable === null ? !!rawItem.photo.reportable : !rawItem.photo.reportable
      } as UpdatePhotoInput
    }))
    setEditingSitePhotos(false)
    fetchInspectionPhotos()
  }

  const [editingMilestones, setEditingMilestones] = useState<boolean>(false)
  const cancelEditingMilestones = () => {
    setChanges(undefined)

    if (inspection?.milestoneTrackers) {
      let _milestoneTrackers: MilestoneTrackerInput[] = inspection.milestoneTrackers.filter(t => t) as MilestoneTrackerInput[]
      setMilestoneTrackers(_milestoneTrackers)
    } else {
      setMilestoneTrackers([])
    }

    setEditingMilestones(false)
  }

  const handleMilestonesChange = (value: MilestoneTrackerInput[]) => {
    setChanges({
      ...changes,
      milestoneTrackers: value
    })
  };

  const saveMilestones = async () => {
    if (!inspection) return

    try {
      setSaving(true)
      await update(changes)
      setEditingMilestones(false)
    } finally {
      setSaving(false)
      setChanges(undefined)
    }
  }

  const [editingSitePhotos, setEditingSitePhotos] = useState<boolean>(false)
  const saveSitePhotos = async() => {
    if (!inspection) return
    const sequence = inspectionPhotos.map(p => p.id)
    await API.graphql(graphqlOperation(updateInspection, { input: { id: inspection.id, _version: inspection._version, photoSequence: sequence } }))
    setEditingSitePhotos(false)
    setReordered(false)
  }

  const cancelEditingSitePhotos = () => {
    setChanges(undefined)
    setEditingSitePhotos(false)
    setReordered(false)
    fetchInspectionPhotos()
  }

  // TODO fix DRY with same function in ObservationLocation.tsx
  // after app wide alert is added.
  async function onChange(e: any) {
    const bucket = awsconfig.aws_user_files_s3_bucket;
    const region = awsconfig.aws_user_files_s3_bucket_region;
    const uploadPhoto: any = async (file: File) => {
      const ext = file!.name!.toLowerCase().split('.').pop()
      if (!['jpeg', 'jpg', 'png'].includes(ext ? ext : '')) {
        setApiMsg(`${file.name} was not uploaded. Wrong file type.`)
        setOpen(true)
        return
      }
      const key = inspection?.clientId + '/' + inspection?.projectId + '/' + inspection?.id + '/' + file.name
      try {
        await Storage.put(`upload/${key}`, file, {
          contentType: 'image/jpg',
        });

        const photo = await API.graphql(graphqlOperation(createPhoto, { input: { 
          groupId: inspection!.groupId || inspection!.projectId,
          file: { 
            bucket: bucket, 
            key: key, 
            region: region 
          }, 
          inspectionId: inspectionId,
          projectId: inspection!.projectId 
        }})) as any
        const photoId = photo.data.createPhoto.id
        await API.graphql(graphqlOperation(createInspectionPhoto, { input: { 
          groupId: inspection!.groupId || inspection!.projectId,
          inspectionId: inspection?.id,
          photoId: photoId,
          projectId: inspection!.projectId 
        }})) as any
        fetchInspectionPhotos()
      } catch (error) {
        setApiMsg(`error.response.data.message`)
        setOpen(true)
      }
    }
    Array.from(e.target.files).forEach(file => uploadPhoto(file, e.target.id))
    setEditingSitePhotos(false)
  }



  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorder(
      inspectionPhotos,
      result.source.index,
      result.destination.index
    );
    setReordered(true)
  }

  if (!inspection) return <LoadingIndicator />

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Field Notes'
            //titleTypographyProps={{ style: { fontWeight: 'bold', TypographyTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {editable && !editingFieldNotes &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingFieldNotes(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="Date of Inspection"
                  //emptyLabel='Date Not Set!'
                  //label={inspectionDate ? '' : 'Date of Inspection'}
                  value={inspectionDate ? inspectionDate : null}
                  invalidDateMessage="Invalid!"
                  onChange={handleInspectionDateChange}
                  InputProps={{ readOnly: !editingFieldNotes || saving }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    disabled: !editingFieldNotes || saving
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <KeyboardTimePicker
                  margin="normal"
                  variant="inline"
                  label="Arrival Time"
                  //mask="__:__ _M"
                  //format="HH:mm a"
                  //format="hh:mm A"
                  format="HH:mm"
                  //emptyLabel="Time Not Set!"
                  value={arrivalTime ? arrivalTime : null}
                  invalidDateMessage="Invalid!"
                  onChange={handleArrivalTimeChange}
                  InputProps={{ readOnly: !editingFieldNotes || saving }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                    disabled: !editingFieldNotes || saving
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="Next Inspection Date"
                  value={nextInspectionDate ? nextInspectionDate : null}
                  onChange={handleNextInspectionDateChange}
                  InputProps={{ readOnly: !editingFieldNotes || saving }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    disabled: !editingFieldNotes || saving
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Check-In"
                  margin="normal"
                  value={checkInContact}
                  onClick={() => { editingFieldNotes && !saving && setEditingCheckInContact(true) }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={!editingFieldNotes || saving} onClick={() => setEditingCheckInContact(true)}>
                          <AccountCircleOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ContactPicker open={editingCheckInContact} hideLabel={true} onClose={() => setEditingCheckInContact(false)} onSelect={handleCheckInChange} />
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={2}>
                  <TextField
                    error={errors.temp}
                    type="number"
                    style={{ width: 75 }}
                    label="Temperature"
                    id='temp'
                    helperText={messages.temp}
                    value={weather && weather.temp ? Math.round(weather.temp) : ''}
                    InputProps={{
                      readOnly: !editable || !editingFieldNotes,
                      endAdornment: <InputAdornment position="end">°F</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleWeatherFieldChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    error={errors.humidity}
                    type="number"
                    style={{ width: 75 }}
                    label="Humidity"
                    id='humidity'
                    helperText={messages.humidity}
                    value={weather && weather.humidity ? Math.round(weather.humidity) : ''}
                    InputProps={{
                      readOnly: !editable || !editingFieldNotes,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleWeatherFieldChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    error={errors.windSpeed}
                    type="number"
                    style={{ width: 90 }}
                    label="Wind Speed"
                    id='wind_speed'
                    helperText={messages.windSpeed}
                    value={weather && weather.wind_speed ? Math.round(weather.wind_speed) : ''}
                    InputProps={{
                      readOnly: !editable || !editingFieldNotes,
                      endAdornment: <InputAdornment position="end">mph</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleWeatherFieldChange}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormControl style={{ width: '125px' }}>
                    <InputLabel shrink id="placeholder-label">
                      Wind Direction
                    </InputLabel>
                    <Select
                      label="Direction"
                      name='wind_deg'
                      value={weather && weather.wind_deg ? degToCard(weather.wind_deg) : ''}
                      onChange={handleWeatherFieldChange}
                      inputProps={{ readOnly: !editable || !editingFieldNotes }}
                    >
                      {allDirections.map((point) =>
                        <MenuItem key={point} value={point}>{point}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  {!editingFieldNotes ?
                    <TextField
                      style={{ width: 300, marginLeft: 100 }}
                      label="Conditions"
                      id='weather'
                      value={weather && weather.weather ? weather.weather.map((condition: any) => condition.description).join(', ') : ' '}
                      InputProps={{
                        readOnly: !editable || !editingFieldNotes,
                      }}
                      onChange={handleWeatherFieldChange}
                    /> :
                    <FormControl style={{ width: 300, marginLeft: 100 }}>
                      <InputLabel shrink id="placeholder-label">
                        Conditions
                      </InputLabel>
                      <Select
                        label="Conditions"
                        name='weather'
                        value={weather && weather.weather ? weather.weather.map((condition: any) => condition.description).join(', ') : ' '}
                        onChange={handleWeatherFieldChange}
                        inputProps={{ readOnly: !editable || !editingFieldNotes }}
                      >
                        {weatherDescriptors.map((desc) =>
                          <MenuItem key={desc} value={desc}>{desc}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={12} >
                  {editingFieldNotes &&
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={!editingFieldNotes}
                      onClick={handleWeatherChange}
                    >
                      Get Weather
                    </Button>
                  }
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    message={apiMsg}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <TextField
                name="notes"
                label="Site Inspection Notes"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                rowsMax={4}
                value={notes}
                onChange={(event: any) => handleNotesChange(event.target.value)}
                InputProps={{
                  readOnly: !editingFieldNotes || saving,
                }}
              />
              {editingFieldNotes &&
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Button
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    color="primary"
                    disabled={saving}
                    onClick={() => cancelEditingFieldNotes()}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => saveFieldNotes()}
                    disabled={!changes || saving}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {isMilestone &&
        <Grid item xs={12}>
          <Card variant="outlined" style={{ width: '100%' }}>
            <CardHeader style={{ backgroundColor: 'silver' }}
              title='Milestones'
              //titleTypographyProps={{ style: { fontWeight: 'bold', TypographyTransform: 'uppercase', fontSize: 14 } }}
              action={
                <div>
                  {editable && !editingMilestones &&
                    <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingMilestones(true)}>
                      <Edit />
                    </IconButton>
                  }
                </div>
              }
            />
            <CardContent>
              <MilestoneGrid inspection={inspection} value={milestoneTrackers} readOnly={!editingMilestones} onChange={handleMilestonesChange} />
              {editingMilestones &&
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Button
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    color="primary"
                    disabled={saving}
                    onClick={() => cancelEditingMilestones()}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => saveMilestones()}
                    disabled={!changes || saving}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              }
            </CardContent>
          </Card>
        </Grid>
      }
      <Grid item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Site Progress Photos'
            //titleTypographyProps={{ style: { fontWeight: 'bold', TypographyTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {editable && !editingSitePhotos &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingSitePhotos(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            {editingSitePhotos &&
              <Typography variant="subtitle1" gutterBottom component="div">
                Drag and drop photos to reorder.
              </Typography>
            }
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!editingSitePhotos} >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {inspectionPhotos.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                item
                              )}
                            >
                              <InspectionPhotoItem 
                                key={item.photoId}
                                item={item}
                                editable={editable && editingSitePhotos}
                                onDelete={deletePhotoItem}
                                warn={undefined}
                                onChangeReportable={changePhotoReportable}
                                reportable={item.photo.reportable}/>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Grid item xs={3}>
              <label htmlFor={"upload-button"}>
                <input
                  disabled={!editingSitePhotos}
                  style={{ display: 'none' }}
                  id={"upload-button"}
                  name="upload-photo"
                  type="file"
                  multiple
                  onChange={(e) => onChange(e)}
                />
                {editingSitePhotos &&
                  <Button
                    style={{ marginTop: 5 }}
                    disabled={!editingSitePhotos || reordered}
                    color="primary"
                    variant="contained"
                    component="span"
                  >
                    Upload Photos
                  </Button>
                }
              </label>
            </Grid>
            {editingSitePhotos &&
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Button
                  variant="outlined"
                  style={{ marginRight: 20 }}
                  color="primary"
                  onClick={() => cancelEditingSitePhotos()}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => saveSitePhotos()}
                  disabled={!reordered || saving}
                >
                  Save
                </LoadingButton>
              </Grid>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

