import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createHierarchyBundle } from '../graphql/mutations';
import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { CreateHierarchyBundleInput } from '../API';
import { HierarchyBundleStatus, HierarchyBundleType } from '../models';
import { LoadingButton } from '../common/LoadingButton';
import { HierarchyCheckboxTree } from '../common/HierarchyCheckboxTree';

export const NewMilestoneBundle = () => {

  const navigate = useNavigate();
  const [bundle, setBundle] = useState<CreateHierarchyBundleInput>({
    name: '',
    type: HierarchyBundleType.MILESTONE,
    status: HierarchyBundleStatus.ACTIVE
  })

  function setBundleValue(key: string, value: any) {
    setBundle({ 
      ...bundle, 
      [key]: value
    })
  }

  const [hierarchyItemIds, setHierarchyItemIds] = useState<string[]>([])
  function onHierarchyChange(value: string[]) {
    setBundleValue('hierarchyItemIds', JSON.stringify(value))
    setHierarchyItemIds(value)
  }

  async function addBundle() {
    try {
      if (!bundle.name) return
      const bundleData = await API.graphql(graphqlOperation(createHierarchyBundle, {input: bundle})) as any
      const newBundle = bundleData.data.createHierarchyBundle
      navigate(`/milestones/bundles/${newBundle.id}`)
    } catch (err) {
      console.log('error creating bundle:', err)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Milestone Bundle
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <TextField 
            label="Milestone Bundle Name" 
            variant="outlined"
            size="small"
            fullWidth
            name="name"
            onChange={(event) => setBundleValue(event.target.name, event.target.value)}
            value={bundle.name}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <HierarchyCheckboxTree 
              onChange={onHierarchyChange}
              mode="include" 
              selected={hierarchyItemIds} 
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton 
          variant="contained" 
          color="primary" 
          onClick={addBundle}
          disabled={!(bundle.name && hierarchyItemIds.length)}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
