import React, { ChangeEvent, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { AddressInput } from '../API';
import states from './states.json'

export const AddressEditor: React.FC<{address: AddressInput, editing: boolean, onSave?: Function, onCancel?: Function, onChange?: Function}> = ({address, editing = false, onSave, onCancel, onChange}) => {
  const [_address, setAddress] = useState<AddressInput>(address)
  const [changes, setChanges] = useState<any>(null)
  
  function setAddressValue(event: ChangeEvent<any>) {
    const newChanges = {
      ...changes,
      [event.target.name]: event.target.value
    }

    setChanges(newChanges)

    setAddress({ 
      ..._address, 
      [event.target.name]: event.target.value
    })

    onChange && onChange(newChanges)
  }

  function save() {
    onSave && onSave(changes)
    setChanges(null)
  }

  function cancel() {
    setAddress(address)
    onCancel && onCancel()
    setChanges(null)
  }

  if (!editing) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>{address.street}</div>
          <div>{address.street2}</div>
          <div>{address.city}</div>
          <div>{address.state}, {address.zip}</div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField 
          label="Street Address" 
          variant="outlined"
          size="small"
          fullWidth
          name="street"
          onChange={setAddressValue}
          value={_address.street}
        />
      </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Street Address 2" 
            variant="outlined"
            size="small"
            fullWidth
            name="street2"
            onChange={setAddressValue}
            value={_address.street2}
          />
        </Grid>
      <Grid item xs={12}>
        <TextField 
          label="City" 
          variant="outlined"
          size="small"
          fullWidth
          name="city"
          onChange={setAddressValue}
          value={_address.city}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            value={_address.state}
            name="state"
            onChange={setAddressValue}
            label="State"
          >
            <MenuItem value={''}></MenuItem>
            {states.map(state => <MenuItem value={state.abbreviation}>{state.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField 
          label="Zip" 
          variant="outlined"
          size="small"
          fullWidth
          name="zip"
          onChange={setAddressValue}
          value={_address.zip}
        />
      </Grid>
      { (onSave || onCancel) &&
        <Grid item xs={12}>
          { onCancel && 
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={cancel}
              style={{ fontWeight: 'bold', textTransform: 'uppercase', marginRight: 10 }}
            >
              Cancel
            </Button>
          }
          { onSave &&
            <Button 
              variant="contained" 
              color="primary" 
              disabled={!changes}
              onClick={save}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Save
            </Button>
          }
        </Grid>
      }
    </Grid>
  )
}