import React, { useCallback, useEffect, useRef, useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { createInspection } from "../graphql/mutations"
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    List,
    ListItem,
    makeStyles,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"

import { Navigate, useNavigate } from "react-router-dom"
import { getHierarchyBundle, getMilestoneTemplate, getUser, hierarchyItemsByHierarchyId, listHierarchies, listProjects, phasesByProjectId } from "../graphql/queries"
import { InspectionStatus } from "../models"
import { useCurrentUser } from "../hooks/AuthHooks"
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles({
    root: {
        height: 110,
        flexGrow: 1,
        maxWidth: 400,
    },
    formControlLabel: {
        margin: 5,
    },
    fabButton: {
        maxHeight: 20,
        minHeight: 20,
        maxWidth: 20,
        minWidth: 20,
        alignSelf: "flex-end",
        marginBottom: 2,
        marginRight: 2
    },
    title: {
        textAlign: 'center',
        paddingTop: 40,
        paddingBottom: 10
    },
    reviewItem: {
        fontStyle: 'bold'
    },
});

const initialValue = {
    status: InspectionStatus.ACTIVEW,
    code: "S",
    hierarchyItemIds: [],
    hierarchyItemNames: [],
    phaseId: null,
    projectId: null,
    projectName: '',
    phaseName: ''
} as any

function getSteps() {
    return ['Select Project', 'Select Inspection Type', 'Select CSI Hierarchy Items', 'Create Inspection'];
}

const debounce = (func: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;

    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const arrayToTree = (list: any) => {
    const map: number[] = [];
    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
    }

    let node
    const roots: any = [];

    for (const item of list) {
        node = item
        if (node.parentId !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') {
            if (list[map[node.parentId]] !== undefined) {
                list[map[node.parentId]].children?.push({ 'id': node.id, 'code': node.code, 'name': node.name, 'inspectionPoints': node.inspectionPoints, 'children': node.children });
            }
        } else {
            roots.push({ 'id': node.id, 'code': `${node.code} ${node.name}`, 'inspectionPoints': node.inspectionPoints, 'children': node.children });
        }
    }
    return { 'id': '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f', 'code': 'CSI Hierarchy', 'inspectionPoints': [], 'children': roots }
}

export default function NewInspection() {
    const navigate = useNavigate()
    const classes = useStyles();
    const user = useCurrentUser()
    const [redirectId, setRedirectId] = useState<string>("")
    const [inspection, setInspection] = useState<any>(initialValue)
    const [projects, setProjects] = useState<any>([])
    const [phases, setPhases] = useState<any[]>([])
    const selectedHierarchyItems = useRef<any[]>([])
    const [items, setItems] = useState<any[]>([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [hierarchyItems, setHierarchyItems] = useState<any[]>([])
    const [expandedNodes, setExpandedNodes] = useState<string[]>(['3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f'])
    const [disableStep0, setDisableStep0] = useState<boolean>(true)
    const steps = getSteps();

    const fetchProjects = useCallback(async () => {
        if (!user) return
        try {
            const response = await API.graphql(graphqlOperation(getUser, {'id': user})) as any
            const userData = response.data.getUser
            const projectData = (await API.graphql(
                graphqlOperation(listProjects, {limit: 1000})
            )) as any
            const projects = projectData.data.listProjects.items
            if ( userData.type !== 'EFT') {
                setProjects(projects)
            } else {
                const filteredProjects = projects.filter((p: any) => JSON.parse(userData.favoriteProjects).includes(p.id))
                setProjects(filteredProjects)
            }
        } catch (err) {
            console.log("error fetching projects")
        }
    },[user])

    useEffect(() => {
        fetchProjects()
    }, [fetchProjects])

    const fetchHierarchyItems = useCallback(async (hierarchyId: string = '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f') => {
        const hierarchyItemsData = await API.graphql(graphqlOperation(hierarchyItemsByHierarchyId, {
            hierarchyId: hierarchyId, limit: 1200
        })) as any
        const hierarchyItems = hierarchyItemsData.data.hierarchyItemsByHierarchyId.items
            .filter((i: any) => !i._deleted)
            .sort((a: any, b: any) => a.code > b.code ? 1 : -1)
        setHierarchyItems(hierarchyItems)
    }, [])

    const fetchHierarchy = useCallback(async () => {
        const hierarchyData = await API.graphql(graphqlOperation(listHierarchies)) as any
        const hierarchies = hierarchyData.data.listHierarchies.items
        if (hierarchies.length) {
            // setHierarchy(hierarchies[0])
            await fetchHierarchyItems(hierarchies[0].id);
        } else {
            // setHierarchy({
            //   name: ''
            // })
        }
    }, [fetchHierarchyItems])

    useEffect(() => {
        fetchHierarchy()
    }, [fetchHierarchy])

    async function addInspection() {
        // First calculate scope from milestone template
        // if it exists.
        let _scope: string[] = []
        let bundleIds: string[] = []
        if (inspection.milestoneTemplateId) {
            const bundles = await API.graphql(graphqlOperation(getMilestoneTemplate, { id: inspection.milestoneTemplateId})) as any
            bundleIds = JSON.parse(bundles.data.getMilestoneTemplate.hierarchyBundleIds)
            for (let bundleId of bundleIds) {
                let bundle = await API.graphql(graphqlOperation(getHierarchyBundle, { id: bundleId})) as any
                if (bundle) {
                    _scope = _scope.concat(
                        bundle.data.getHierarchyBundle.hierarchyItemIds
                            ? (JSON.parse(bundle.data.getHierarchyBundle.hierarchyItemIds) as string[])
                            : [],
                    )
                }
            }
        } else {
            _scope = inspection.hierarchyItemIds
        }
        try {
            const inspectionId = uuidv4()
            const inspectionData = (await API.graphql(
                graphqlOperation(createInspection, {
                    input: {
                        id: inspectionId,
                        groupId: inspection.phaseId ? inspection.phaseId : inspection.projectId,
                        userId: user,
                        projectId: inspection.projectId,
                        status: InspectionStatus.ACTIVEW,
                        phaseId: inspection.phaseId ? inspection.phaseId : null,
                        milestoneBundleIds: bundleIds.length ? JSON.stringify(bundleIds) : null,
                        scope: JSON.stringify(_scope),
                        code: inspection.code
                    },
                })
            )) as any

            setRedirectId(inspectionData.data.createInspection.id)
            setInspection(initialValue)
        } catch (err) {
            console.log("error creating inspection:", err)
        }
    }

    const handleNext = () => {

        if (activeStep === steps.length - 1) {

            addInspection()
        }

        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === 1 && inspection.milestoneTemplateId) {
                // Skip over selecting hierarchyitems to inspect
                // as they are determined by milestone template.
                return prevActiveStep + 2
            } else {
                return prevActiveStep + 1
            }
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === 3 && inspection.milestoneTemplateId) {
                // Skip over selecting hierarchyitems to inspect
                // as they are determined by milestone template.
                return prevActiveStep - 2
            } else {
                return prevActiveStep - 1
            }
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const searchChange = async (value: string) => {
        if (value !== "") {
            //search for items
            var regex = RegExp(`.*?${value.toLowerCase()}.*`);
            const foundItems = hierarchyItems.filter((elem: any) =>
              regex.test(elem.name.toLowerCase())
            )
            const allParentNodes: any = ['3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f']
            // Open up all parent nodes.
            foundItems.forEach((item: any) => {
                let a = hierarchyItems.find((i: any) =>
                    i.id === item.id
                )
                while (a) {
                  allParentNodes.unshift(a.id)
                  for (const item of hierarchyItems) {
                    if (item.id === a.parentId) {
                        a = item
                        break
                    }
                }
                a = null
                }

            }
            )
            setExpandedNodes(allParentNodes)
          }
    }

    const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        //event.preventDefault()
        //setExpandedNodes(nodeIds);
    };

    const handleNodeSelect = (event: React.ChangeEvent<{}>) => {
        event.preventDefault()
    };

    const handleAdd = (event: any, nodeId: string) => {
        // Behavior on the mobile app is:
        // When checking a node all children nodes are checked and all parent nodes are checked.
        // When unchecking a node all children nodes are unchecked and all parent nodes are left checked.
        event.preventDefault()
            const addChildren = (node: any) => {
                for (const child of node.children) {
                    addChildren(child)
                }
                if (event.target.checked) {
                    selectedHierarchyItems.current = [...selectedHierarchyItems.current, node]
                } else {
                    selectedHierarchyItems.current = selectedHierarchyItems.current.filter((i: any) => i.id !== node.id)

                }
            }

            const addParents = (node: any) => {
                let parent = hierarchyItems.find((item: any) => item.id === node.parentId)
                while (parent) {
                    selectedHierarchyItems.current = [...selectedHierarchyItems.current, parent]
                    for (const item of hierarchyItems) {
                        if (item.id === parent.parentId) {
                            parent = item
                            break
                        }
                    }
                    parent = null
                }
            }
            const node = hierarchyItems.find((item: any) => item.id === nodeId)
            addChildren(node)
            if (event.target.checked) {
                addParents(node)
            }
            setItems([...selectedHierarchyItems.current])
            setExpandedNodes([...expandedNodes, ...selectedHierarchyItems.current.map((i: any) => i.id)]);

    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInspection({...inspection, "code": (event.target as any).value});
    };

    const handleProjectChange = async (value: any, reason: string) => {
        if (reason !== 'select-option') return
        try {
            const phasesData = await API.graphql(graphqlOperation(phasesByProjectId, {
                projectId: value.id, filter: {status: {eq: "ACTIVE"}}
            })) as any
            const phases = phasesData.data.phasesByProjectId.items
            setPhases(phases)
            if (phases.length) {
                setDisableStep0(true)
            } else {
                setDisableStep0(false)
            }
            setInspection({
                ...inspection,
                'projectId': value.id,
                'projectName': value.name,
                'phaseId': null,
                'phaseName': ' ',
                'milestoneTemplateId': value.milestoneTemplateId
            })
        } catch (err) {
            console.log("error fetching phases")
        }
    };

    const handlePhaseChange = (value: any, reason: string) => {
        if (reason !== 'select-option') return
        setInspection({
            ...inspection,
            'phaseId': value.id,
            'phaseName': value.name,
            // Template on phase supercedes template on project
            'milestoneTemplateId': value.milestoneTemplateId ? value.milestoneTemplateId : inspection.milestoneTemplateId
        })
        setDisableStep0(false)
    }

    const handleProjectInputChange = (value: any, reason: string) => {
        if (reason !== 'clear') return
        setInspection({...inspection, 'projectId': '', 'projectName': ''})

    }

    const handlePhaseInputChange = (value: any, reason: string) => {
        if (reason !== 'clear') return
        setInspection({...inspection, 'phaseId': '', 'phaseName': ''})
    }

    const renderTree = (n: any) => (
        <TreeItem
            id={n.id}
            key={n.id}
            nodeId={n.id}
            label={
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Typography>{n.code} {n.name}</Typography>
                    { n.id !== '3324cb39-8f1c-4324-ba0b-5c3cc6f3c72f' &&
                      <Checkbox
                        checked= {items.length ? items.map((i: any) => i.id).includes(n.id) : false}
                        onChange={(event: any) => handleAdd(event, n.id)} aria-label="add"></Checkbox>
                    }
                </Box>
            }
        >
            {Array.isArray(n.children) ? n.children.map((blah: any) => renderTree(blah)) : null}
        </TreeItem>
    );

    const handleCancel = () => {

        setInspection(initialValue)
        navigate('/inspections')
    }

    if (redirectId !== "") {
        return <Navigate to={`/inspection/${redirectId}`} />
    }

    return (
        <div>
            <Box className={classes.title}>
                <Typography variant="h4">Create New Inspection</Typography>
            </Box>
            <Box width="80%" alignSelf={'center'}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={handleReset}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Button disabled={activeStep === 0} onClick={handleBack}>
                                    Back
                                </Button>
                                <Button
                                    disabled={
                                        (activeStep === 0 && disableStep0) ||
                                        (activeStep === 1 && !inspection.code) ||
                                        (activeStep === 2 && !inspection.hierarchyItemIds) ||
                                        (activeStep === 3 && false)
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                                <Button onClick={() => handleCancel()} color="secondary">Cancel</Button>
                            </div>
                        </div>
                    )}
                </div>
                <Box marginTop={10}>
                    {activeStep === 0 &&
                        <>
                            <Autocomplete
                                id="project-pick"
                                options={projects}
                                clearOnBlur={false}
                                value={inspection!.projectId}
                                inputValue={ inspection.projectName}
                                getOptionSelected={(item: any) => item.id}
                                getOptionLabel={(option: any) => option.name ? option.name : ''}
                                onChange={(event, newValue, reason) => handleProjectChange(newValue, reason)}
                                onInputChange={(event, newValue, reason) => handleProjectInputChange(newValue, reason)}
                                style={{ width: 300, marginBottom: 10 }}
                                renderInput={(params) => <TextField {...params} label="Project" variant="outlined" />}
                            />
                            { Boolean(phases.length) &&
                                <Autocomplete
                                    id="phase-pick"
                                    options={phases}
                                    clearOnBlur={false}
                                    getOptionSelected={(item: any) => item.id}
                                    value={inspection!.phaseId}
                                    inputValue={inspection.phaseName}
                                    getOptionLabel={(option: any) => option.name ? option.name : ''}
                                    onChange={(event, newValue, reason) => handlePhaseChange(newValue, reason)}
                                    onInputChange={(event, newValue, reason) => handlePhaseInputChange(newValue, reason)}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Phase" variant="outlined" />}
                                />
                            }
                        </>
                    }
                    {activeStep === 1 &&
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Inspection Type</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={inspection.code} onChange={handleTypeChange}>
                                <FormControlLabel value="S" control={<Radio />} label="Standard" />
                                <FormControlLabel value="WT" control={<Radio />} label="Water Test" />
                                <FormControlLabel value="IRT" control={<Radio />} label="Infrared" />
                                <FormControlLabel value="EX" control={<Radio />} label="Excluded" />
                            </RadioGroup>
                        </FormControl>
                    }
                    {activeStep === 2 &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField onChange={debounce((event: any) => searchChange(event.target.value), 1000)} label="Search..." variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TreeView
                                    className={classes.root}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    expanded={expandedNodes}
                                    selected={inspection.hierarchyItemIds}
                                    onNodeToggle={handleNodeToggle}
                                    onNodeSelect={handleNodeSelect}
                                    multiSelect
                                >
                                    {hierarchyItems ? renderTree(arrayToTree(hierarchyItems)) : null}
                                </TreeView>
                            </Grid>
                        </Grid>
                    }
                    {activeStep === 3 &&
                        <Box>
                            <Typography variant='h4'>Review</Typography>
                            <List>
                                <ListItem>
                                    <Typography variant='h6'>Project: {inspection.projectName}</Typography>
                                </ListItem>
                                { inspection.phaseId !== ' ' &&
                                <ListItem>
                                    <Typography variant='h6'>Phase: {inspection.phaseName}</Typography>
                                </ListItem>
                                }
                                <ListItem>
                                    <Typography variant='h6'>Inspection Type: {inspection.code}</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='h6'>Hierarchy Items: {inspection.phaseId ? 'Determined by milestone template' : items.map((i: any) => <div key={i.id}>{i.code} {i.name}</div>)}</Typography>
                                </ListItem>
                            </List>
                        </Box>

                    }
                </Box>
            </Box>
        </div>
    );

}
