import { Box, Checkbox, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { MilestoneTrackerInput, UnitInput, UpdateHierarchyBundleInput, UpdateInspectionInput, UpdatePhaseInput } from '../API';
import { getHierarchyBundle } from '../graphql/queries';

export const MilestoneGrid: React.FC<{inspection: UpdateInspectionInput, value: MilestoneTrackerInput[], readOnly?: boolean, onChange?: (value: MilestoneTrackerInput[]) => void}> = ({inspection, value, readOnly, onChange}) => {
  const [bundles, setBundles] = useState<UpdateHierarchyBundleInput[]>([])
  const [units, setUnits] = useState<UnitInput[]>([])

  useEffect(() => {
    setValue(value)
  }, [value])

  const initialize = useCallback(async () => {
    const rawInspection = inspection as any
    if (rawInspection.phase) {
      let phase: UpdatePhaseInput = rawInspection.phase
      let units: UnitInput[] = (phase.units ? phase.units.filter(p => p) : []) as UnitInput[]
      setUnits(units)
    } else {
      setUnits([])
    }

    if (inspection.milestoneBundleIds) {
      let milestoneBundles: UpdateHierarchyBundleInput[] = []
      let milestoneBundleIds: string[] = JSON.parse(inspection.milestoneBundleIds)
      for (let milestoneBundleId of milestoneBundleIds) {
        const bundleData = await API.graphql(graphqlOperation(getHierarchyBundle, { id: milestoneBundleId })) as any
        const bundle = bundleData.data.getHierarchyBundle
        milestoneBundles.push(bundle)
      }
      setBundles(milestoneBundles)
    } else {
      setBundles([])
    }
  }, [inspection])

  useEffect(() => {
    initialize()
  }, [initialize])

  const [_value, setValue] = useState<any[]>([])

  const onCheckAll = (milestoneId: string) => {
    let newValue = [ ..._value ]

    let trackerIdx = newValue.findIndex(m => m.milestoneId === milestoneId)
    if (trackerIdx < 0) {
      newValue.push({
        milestoneId: milestoneId,
        all: true,
        units: []
      })
    } else {
      const tracker = newValue[trackerIdx]
      newValue[trackerIdx] = {
        milestoneId: tracker.milestoneId,
        all: !tracker.all,
        units: tracker.units
      }
    }
    setValue(newValue)
    onChange && onChange(newValue)
  }

  const onCheckOne = (milestoneId: string, unitId: string) => {
    let newValue = [ ..._value ]

    let trackerIdx = newValue.findIndex(m => m.milestoneId === milestoneId)
    if (trackerIdx < 0) {
      newValue.push({
        milestoneId: milestoneId,
        units: [unitId]
      })
    } else {
      const tracker = newValue[trackerIdx]
      const units = tracker.units 
        ? [ ...tracker.units] 
        : []

      const unitIdx = units.indexOf(unitId)

      if (unitIdx > -1) {
        units.splice(unitIdx, 1)
      } else {
        units.push(unitId)
      }

      newValue[trackerIdx] = {
        milestoneId: tracker.milestoneId,
        all: tracker.all,
        units: units
      }
    }
    setValue(newValue)
    onChange && onChange(newValue)
  }

  return (
    <Box style={{ 
      backgroundColor: '#ffffff',
      borderColor: '#CCCCCC',
      borderWidth: 1,
      borderRadius: 6,
      padding: 20
    }}>
      <Box display="flex" flex={1} flexDirection="row" alignItems="center">
        <Box style={{ width: 200 }}></Box>
        <Typography style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
          {units.length 
            ? 'All'
            : ''
          }
        </Typography>
        {units?.map(unit => 
          <Typography key={unit.id} style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{unit.name}</Typography>
        )}
      </Box>
      {bundles.map(bundle => {
        let milestoneTracker = _value.find(m => m.milestoneId === bundle.id)
            
        return (
          <Box key={bundle.id} display="flex" flexDirection="row">
            <Box display="flex" width={200} alignItems="center">
                <Typography style={{ fontWeight: 'bold' }}>{bundle.name}</Typography>
            </Box>
            <Box display="flex" flex={1} justifyContent="center">
                <Checkbox
                    checked={milestoneTracker?.all || false}
                    onChange={() => {onCheckAll(bundle.id)}}
                    color="primary"
                    disabled={readOnly}
                />
            </Box>
            {units?.map(unit => 
                <Box key={unit.id} display="flex" flex={1} justifyContent="center">
                    <Checkbox
                        checked={milestoneTracker?.units?.includes(unit.id) || false}
                        onChange={() => { onCheckOne(bundle.id, unit.id!) }}
                        color="primary"
                        disabled={readOnly || milestoneTracker?.all}
                    />
                </Box>
            )}
          </Box>
        )
      })}
    </Box>
  )
}