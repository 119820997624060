import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem } from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { UpdateContactInput } from '../API';

export type ProjectContact = {
  id: string
  projectId: string
  contactId: string
  contact: UpdateContactInput
  label?: string
  _version: any
  _deleted?: boolean
}

export type ClientContact = {
  id: string
  clientId: string
  contactId: string
  contact: UpdateContactInput
  label?: string
  _version: any
  _deleted?: boolean
}

export const ContactListItem: React.FC<{item: ClientContact | ProjectContact, onDelete: Function, onSetPrimary?: Function}> = ({item, onDelete, onSetPrimary}) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'contactActionPopup' })
  const [contact] = useState<UpdateContactInput>(item.contact)

  const _onDelete = () => {
    onDelete && onDelete(item)
    popupState.close()
  }

  const _onSetPrimary = () => {
    onSetPrimary && onSetPrimary(item)
    popupState.close()
  }


  return (
    <ListItem key={item.id} alignItems="flex-start" style={{ alignItems: 'center' }}>
      <ListItemAvatar>
        <Avatar>
          {`${contact?.firstName ? contact?.firstName[0] : ''}${contact?.lastName ? contact?.lastName[0] : ''}`}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{flex: 1}}
        primary={`${contact?.firstName} ${contact?.lastName}`}
        secondary={contact?.companyName}
      />
      <ListItemText
        style={{flex: 1}}
        primary={contact?.email}
        secondary={contact?.phone}
      />
      <ListItemText
        style={{flex: '.5'}}
      >
        {item.label}
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" {...bindTrigger(popupState)}>
          <MoreHorizOutlined />
        </IconButton>
        <Menu {...bindMenu(popupState)}>
          { onSetPrimary && <MenuItem onClick={ _onSetPrimary}>Set Primary</MenuItem> }
          <MenuItem onClick={ _onDelete}>Remove</MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export const ContactList: React.FC<{items: Array<ProjectContact | ClientContact>, primaryId?: string | null | undefined, onDelete: Function , onSetPrimary: Function }> = ({items, primaryId, onDelete, onSetPrimary}) => {
  const [primaryItem, setPrimaryItem] = useState<ClientContact | ProjectContact>()
  const [otherItems, setOtherItems] = useState<Array<ClientContact | ProjectContact>>([])

  useEffect(() => {
    if (items && primaryId) {
      setPrimaryItem(items.find(i => i.contact.id === primaryId))
      setOtherItems(items.filter(i => i.contact.id !== primaryId))
    } else {
      setPrimaryItem(undefined)
      setOtherItems(items || [])
    }
  }, [primaryId, items])

  
  return (
    <List
      component="div"
      dense={true}
    >
      <ListSubheader style={{ textTransform: 'uppercase', fontWeight: 500 }}>
        Primary Contact
      </ListSubheader>
      { primaryItem && <ContactListItem key={primaryItem.id} item={primaryItem} onDelete={onDelete} /> }
      { !primaryItem &&
        <ListItem alignItems="flex-start" style={{ justifyContent: 'center' }}>
          No Primary Contact Defined
        </ListItem>
      }
      { otherItems.length > 0  && 
        <div>
          <ListSubheader style={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Other Contacts
          </ListSubheader>
          { otherItems.map(item => <ContactListItem key={item.id} item={item} onDelete={onDelete} onSetPrimary={onSetPrimary} /> )}
        </div>
      }
    </List>
  )
}