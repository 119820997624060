import React, { useCallback, useEffect, useState } from "react"
import { Avatar, Box, Typography } from "@material-ui/core"
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";

type Props = {
  item: any
};

export const CommentListItem: React.FC<Props> = (props) => {
  const { item } = props
  const [shortName, setShortName] = useState<string>('?')

  const [user, setUser] = useState<any>()
  const fetchUser = useCallback(async () => { 
    const userData = await API.graphql(graphqlOperation(getUser, { id: item.userId })) as any
    const user = userData.data.getUser

    if (user) {
      setShortName(`${user?.firstName[0]}${user?.lastName[0]}`)
    } else {
      setShortName('?')
    }

    setUser(user)
  }, [item])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  if (!item) return <></>

  return (
    <Box key={item.id}>
      <Box display="flex" flexDirection="row">
        <Box style={{ marginRight: 15, marginTop: 5 }}>
          <Avatar>
            {shortName}
          </Avatar>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" style={{ marginBottom: 5}}>
            <Typography style={{fontSize: 14, color: '#767676', marginRight: 10}}>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography style={{fontSize: 14, color: '#767676'}}>
              {new Date(item.date).toLocaleDateString()}
            </Typography>
          </Box>
          <Box style={{ backgroundColor: '#c0c0c0', borderRadius: 6, padding: 10 }}>
            <Typography style={{fontSize: 14}}>
              {item.text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}