import React from "react"
import { Box, Card, CardActionArea, CardContent, Chip, Grid, Link, Typography } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"

const workingDays = (startDate: Date) => {
  let count = 0;
  const endDate = new Date(Date.now())
  const curDate = startDate
  while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}

export default function DashboardCard(props: any) {

  const renderSwitch = (param: any) => {
    const delta = Math.round((Date.now() - Date.parse(param)) / (1000 * 3600 * 24))
    if (delta > 3) {
      return { label: 'Overdue', color: 'secondary' } as any
    } else if (delta >= 0 && delta <= 3) {
      return { label: 'Current', color: 'primary' } as any
    } else {
      return { label: 'Future', color: 'default' } as any
    }
  }

  return (
    <Box m={2}>
      <Link underline='none' component={RouterLink} to={`/inspection/${props.inspection.id}`}>
        <Card style={{ backgroundColor: "lightgray" }}>
          <CardActionArea >
            <CardContent>
              <Grid container>
                <Grid item xs container direction="column">
                  <Grid item xs>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {props.inspection.project.name}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                      {props.inspection.phase ? props.inspection.phase.name : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                      {props.inspection.project.client ? props.inspection.project.client.name : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    Project Reviewer: {props.inspection.project.reviewer ? props.inspection.project.reviewer.id : ''}
                  </Grid>
                  <Grid item xs>
                    Inspection Reviewer: {props.inspection.reviewer ? props.inspection.reviewer.id : ''}
                  </Grid>
                </Grid>
                <Grid item xs container direction="column">
                  <Grid item xs>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Inspection Date</Typography>
                  </Grid>
                  <Grid item xs>
                    {new Date(props.inspection.inspectionDate).toLocaleDateString()}
                  </Grid>
                </Grid>
                {props.extraInfo &&
                  <>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Age</Typography>
                      </Grid>
                      <Grid item xs>
                        { workingDays(new Date(props.inspection.inspectionDate))}
                      </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Aging Status</Typography>
                      </Grid>
                      <Grid item xs>
                        <Chip
                          label={renderSwitch(props.inspection.inspectionDate).label}
                          color={renderSwitch(props.inspection.inspectionDate).color}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Inspection Status</Typography>
                      </Grid>
                      <Grid item xs>
                        {props.inspection.status}
                      </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Last Comment</Typography>
                      </Grid>
                      {props.inspection.latestComment &&
                        <>
                          <Grid item xs>
                            {`By: ${props.inspection.latestComment ? props.inspection.latestComment.comment.user.firstName : ''} 
        ${props.inspection.latestComment ? props.inspection.latestComment.comment.user.lastName : ''}`}
                          </Grid>
                          <Grid item xs>
                            {`Comment: ${props.inspection.latestComment ? props.inspection.latestComment.comment.text : ''}`}
                          </Grid>
                        </>
                      }
                    </Grid>
                  </>
                }
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  )
}