import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { findingsByProjectId, listUsers, observationsByInspectionId } from '../graphql/queries';
import { FindingStatus, FindingType, ObservationType } from '../models';
import { Edit } from '@material-ui/icons';
import { LoadingButton } from '../common/LoadingButton';
import { InspectionContext } from './InspectionContext';
import { InspectionNumberEditor } from './InspectionNumberEditor';
import { InspectionStatus } from '../models';
import { CreateActivityStreamInput, CreateUserInput } from '../API';
import { ActivityStream } from './ActivityStream'

type Props = {
  reviewer : string | undefined | null
  activityStream : CreateActivityStreamInput[]
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    '&$disabled': {
      color:'black'
    },
  },
  disabled: {}
}));

export const InspectionOverview: React.FC<Props> = (props) => {

  const classes = useStyles();
  const {inspection, editable, update}  = useContext(InspectionContext);
  const rawInspection = inspection as any

  const [openNciCount, setOpenNciCount] = useState<number>(0)
  const [openIocCount, setOpenIocCount] = useState<number>(0)
  const [newNciCount, setNewNciCount] = useState<number>(0)
  const [newIocCount, setNewIocCount] = useState<number>(0)
  
  const [reviewers, setReviewers] = useState<CreateUserInput[]>([])
  const [reviewer, setReviewer] = useState<string>('')

  const fetchFindings = useCallback(async () => {
    if (inspection) {
      const findingsData = await API.graphql(graphqlOperation(findingsByProjectId, { projectId: inspection.projectId, limit: 1000 })) as any
      let findings = findingsData.data.findingsByProjectId.items as any[]
      // Making the logic here the same as in the report lambda.
      // Which makes the counts appear as they would have on the inspection date.
      findings = findings.filter(f => !f._deleted && (f.inspectionId === inspection!.id || f.date <= (inspection!.inspectionDate || Date.now())))
      if (inspection.phaseId) {
        findings = findings.filter(f => f.phaseId === inspection.phaseId);
      }

      let openNciCount = 0
      let openIocCount = 0
      let newNciCount = 0
      let newIocCount = 0

      for (let f of findings) {
        if (f.status === 'NEW' && (f.inspectionId === inspection.id)) {
          f.status = 'OPEN';
        }

        if (f.status !== 'NEW') {
          if (f.closingObservation) {

            // Only include counts of closing observations from this inspection or older inspections.
            // This removes findings closed on an inspection after this date.
            if (f.closedDate > (inspection!.inspectionDate || Date.now())) {
              f.status = 'OPEN';
            }
          }

          if (f.status === FindingStatus.OPEN || f.inspectionId === inspection!.id) {
            if (f.type === FindingType.NCI) {
              openNciCount++
              if (f.inspectionId === inspection.id) {
                newNciCount++
              }
            } else if (f.type === FindingType.IOC) {
              openIocCount++
              if (f.inspectionId === inspection.id) {
                newIocCount++
              }
            }
          }
        }
      }
      setOpenNciCount(openNciCount)
      setOpenIocCount(openIocCount)
      setNewNciCount(newNciCount)
      setNewIocCount(newIocCount)
    }
  }, [inspection])

  useEffect(() => {
    fetchFindings()
  }, [fetchFindings])

  useEffect(() => {
    const fetchReviewers = async () => {
      const data = await API.graphql(graphqlOperation(listUsers, { filter: {status: {eq: "ACTIVE"},  or: [{ type: { eq: "REVIEWER" } }, { type: { eq: "ADMIN" } }] } })) as any
      setReviewers(data.data.listUsers.items.filter((r: any) => r._deleted !== true))
    }
    fetchReviewers()
      .catch(console.error);
  },[inspection] )

  const [resolutionCount, setResolutionCount] = useState<number>(0)
  const fetchObservations = useCallback(async () => { 
    if (inspection) {
      const observationsData = await API.graphql(graphqlOperation(observationsByInspectionId, { inspectionId: inspection.id })) as any
      let observations = observationsData.data.observationsByInspectionId.items as any[]
      observations = observations.filter(o => !o._deleted)

      setResolutionCount(observations.filter(o => o.type === ObservationType.RESOLUTION).length)
    }
  }, [inspection])

  const [editingOverview, setEditingOverview] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [changes, setChanges] = useState<any>()
  
  const [inspectionNumber, setInspectionNumber] = useState<string>('')
  const updateInspectionNumber = (code: string, number?: string | null) => {
    if (code === 'EX') {
      setInspectionNumber('Excluded')
    } else if (number && code === 'S') {
      setInspectionNumber(number)
    } else if (number && code) {
      setInspectionNumber(`${code}-${number}`)
    } else {
      setInspectionNumber('Not Finalized')
    }
  }

  useEffect(() => {
    if (inspection) {
      updateInspectionNumber(inspection.code!, inspection.number)
    } else {
      setInspectionNumber('')
    }
  }, [inspection])

  const [editInspectionNumber, setEditInspectionNumber] = useState<boolean>(false)
  const changeInspectionNumber = (code: string, number?: string) => {
    setChanges({
      ...changes,
      code: code,
      number: number
    })
    updateInspectionNumber(code, number)
    setEditInspectionNumber(false)
  }

  const cancelEditingOverview = () => {
    setChanges(undefined)
    updateInspectionNumber(inspection!.code!, inspection!.number)
    setReviewer(rawInspection.reviewer.id)
    setEditingOverview(false)
  }
  
  const saveOverview = async () => {
    if (!inspection) return

    try {
      setSaving(true)
      await update(changes)
      setChanges(undefined)
      setEditingOverview(false)
    } finally {
      setSaving(false)
    }
  }

  const handleReviewerChange = (e: any) => {
    setReviewer(e.target.value)
    setChanges({
      ...changes,
      inspectionReviewerId: e.target.value
    })

  }

  useEffect(() => {
    fetchObservations()
  }, [fetchObservations])

  useEffect(() => {
    setReviewer(props.reviewer ? props.reviewer : '')
  }, [props.reviewer])

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver'}}
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                { editable && !editingOverview && inspection!.status !== InspectionStatus.ACTIVEW &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingOverview(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            <Grid container item xs={12} spacing={3}>
              <Grid container item xs={6}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Inspected Date:
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Inspection Number:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {inspection?.inspectionDate ? new Date(inspection.inspectionDate).toLocaleDateString(): ''}
                  </Typography>
                  <TextField
                    name="number"
                    fullWidth
                    size="small"
                    value={inspectionNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={() => (editingOverview && !saving) && setEditInspectionNumber(true)}
                  />
                  <InspectionNumberEditor 
                    open={editInspectionNumber} 
                    code={changes?.code || inspection!.code} 
                    number={changes?.number || inspection!.number} 
                    projectId={inspection!.projectId!} 
                    phaseId={inspection!.phaseId} 
                    onSave={(code, number) => changeInspectionNumber(code, number)}
                    onCancel={() => setEditInspectionNumber(false)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    EFT:
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Reviewing Engineer:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {rawInspection.user.firstName} {rawInspection.user.lastName}
                  </Typography>
                  <FormControl disabled={!editable || !editingOverview || inspection!.status === InspectionStatus.ACTIVEW} >
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={reviewers.length ? reviewer : ''}
                      onChange={(e) => handleReviewerChange(e)}
                      inputProps={{
                        classes:{
                          root: classes.inputRoot,
                          disabled: classes.disabled
                        }
                      }}
                    >
                      {reviewers.map(r => 
                        <MenuItem key={r.id} value={r.id!}>{r.firstName} {r.lastName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            { editingOverview &&
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Button 
                  style={{ marginRight: 20 }} 
                  variant="outlined" 
                  color="primary"
                  disabled={saving}
                  onClick={() => cancelEditingOverview()}
                >
                  Cancel
                </Button>
                <LoadingButton 
                  variant="contained" 
                  color="primary"
                  onClick={() => saveOverview()}
                  disabled={!changes || saving}
                >
                  Save
                </LoadingButton>
              </Grid>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Card variant="outlined">
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textTransform: 'uppercase' }}>
                <Typography variant="h3">{openNciCount}</Typography>
                <div style={{ fontSize: 12 }}>Outstanding NCIs</div>
                <div style={{ fontSize: 12, fontWeight: 'bold' }}>{newNciCount} NEW</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant="outlined">
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textTransform: 'uppercase' }}>
                <Typography variant="h3">{openIocCount}</Typography>
                <div style={{ fontSize: 12 }}>Outstanding IOCs</div>
                <div style={{ fontSize: 12, fontWeight: 'bold' }}>{newIocCount} NEW</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant="outlined" style={{height: '100%'}}>
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textTransform: 'uppercase' }}>
                <Typography variant="h3">{resolutionCount}</Typography>
                <div style={{ fontSize: 12 }}>Corrections this visit</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography>Counts are calculated as of the inspection date.</Typography>
        </Grid>
      <Grid container item xs={12} spacing={3}>
        <ActivityStream activityStreamItems={props.activityStream} />
      </Grid>
      </Grid>
    </Grid>
  );
}
